import { useTheme } from '@emotion/react';
import { Box, Button, FormControl, Grid, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { addProfileData } from '../../redux/actions/multiProfileActions';
import countries from "../../components/countries.json"

function CreateProfile() {
  const params = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();

  // State variables for form fields
  const [name, setName] = useState('');
  const [about, setAbout] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [address, setAddress] = useState('');
  const [district, setDistrict] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [pincode, setPincode] = useState('');
  const [taxId, setTaxId] = useState('');
  const [owner, setOwner] = useState('');
  const [profileType, setProfileType] = useState(params?.profile);

  const [errors, setErrors] = useState({});

  // Validation logic
  const validateField = (field, value) => {
    let errorMsg = '';

    switch (field) {
      case 'mobile':
        if (!/^\d{10}$/.test(value)) errorMsg = 'Mobile number must be 10 digits';
        break;
      case 'pincode':
        if (!/^\d+$/.test(value)) errorMsg = 'Pincode must contain only digits';
        break;
      case 'email':
        if (!/\S+@\S+\.\S+/.test(value)) errorMsg = 'Invalid email address';
        break;
      default:
        if (!value) errorMsg = 'This field is required';
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: errorMsg,
    }));
  };

  // Handlers for input changes with validation
  const handleMobileChange = (e) => {
    const value = e.target.value;
    setMobile(value);
    validateField('mobile', value);
  };

  const handlePincodeChange = (e) => {
    const value = e.target.value;
    setPincode(value);
    validateField('pincode', value);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    validateField('email', value);
  };

  const navigate = useNavigate()

  // Add profile details
  const addProfileDetails = async () => {
    const formData = { name, about, email, mobile1: mobile, address, district, state, country, pincode, taxId, owner, profileType,country_code: selectedCountry };
    await dispatch(addProfileData(formData));
    navigate(`/dashboard/my-profile`);
  };

  // Check if form is valid
  const isFormValid = () => {
    return Object.values(errors).every((err) => !err) && name && about && email && mobile;
  };

    //countries change
    const [selectedCountry, setSelectedCountry] = useState(countries[0]);
    const [selectedPhoneLength, setSelectedPhoneLength] = useState(countries[0].phoneLength);

    const handleCountryChange = (event) => {
        const country = countries.find((c) => c.dial_code === event.target.value);
        setSelectedCountry(country);
        setSelectedPhoneLength(country?.phoneLength);
    }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            padding: 2,
            borderRadius: '8px',
            backgroundColor: '#E2EAF4',
            marginLeft: { md: 10, xs: 0 },
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#eceff1',
              padding: 2,
              color: theme.palette.text.primary,
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography>Create Profile for {params.profile}</Typography>

              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                <TextField
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  error={!!errors.name}
                  helperText={errors.name}
                  fullWidth
                />
                <TextField
                  label="About"
                  value={about}
                  onChange={(e) => setAbout(e.target.value)}
                  error={!!errors.about}
                  helperText={errors.about}
                  fullWidth
                />
              </Box>

              {params.profile !== 'personal' && (
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                  <TextField
                    label="Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    error={!!errors.address}
                    helperText={errors.address}
                    fullWidth
                  />
                  <TextField
                    label="District"
                    value={district}
                    onChange={(e) => setDistrict(e.target.value)}
                    error={!!errors.district}
                    helperText={errors.district}
                    fullWidth
                  />
                </Box>
              )}

              {params.profile !== 'personal' && (
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                  <TextField
                    label="State"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    error={!!errors.state}
                    helperText={errors.state}
                    fullWidth
                  />
                  <TextField
                    label="Country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    error={!!errors.country}
                    helperText={errors.country}
                    fullWidth
                  />
                  <TextField
                    label="Pincode"
                    value={pincode}
                    onChange={handlePincodeChange}
                    error={!!errors.pincode}
                    helperText={errors.pincode}
                    fullWidth
                  />
                </Box>
              )}

              {(params.profile === 'company' || params.profile === 'small_business' || params.profile === 'organization') && (
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                  <TextField
                    label="TaxId"
                    value={taxId}
                    onChange={(e) => setTaxId(e.target.value)}
                    error={!!errors.taxId}
                    helperText={errors.taxId}
                    fullWidth
                  />
                  <TextField
                    label="Owner"
                    value={owner}
                    onChange={(e) => setOwner(e.target.value)}
                    error={!!errors.owner}
                    helperText={errors.owner}
                    fullWidth
                  />
                </Box>
              )}

              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                <FormControl>
                  <TextField
                    label="Mobile"
                    value={mobile}
                    onChange={handleMobileChange}
                    error={!!errors.mobile}
                    helperText={errors.mobile}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Select
                            value={selectedCountry.dial_code}
                            onChange={handleCountryChange}
                            renderValue={() => (
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                  src={selectedCountry.flag}
                                  alt={selectedCountry.name}
                                  style={{ width: 24, height: 16, marginRight: 8 }}
                                />
                                {selectedCountry.dial_code}
                              </div>
                            )}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  boxShadow: 'none',
                                  border: 'none',
                                  mt: 1,
                                  "& .MuiMenuItem-root": {
                                    padding: '8px 16px',
                                  }
                                }
                              }
                            }}
                            sx={{
                              border: 'none',
                              '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: 'none',
                              },
                            }}
                          >
                            {countries.map((country) => (
                              <MenuItem key={country.dial_code} value={country.dial_code}>
                                <img
                                  src={country.flag}
                                  alt={country.name}
                                  style={{ width: 24, height: 16, marginRight: 8 }}
                                />
                                {country.name} ({country.dial_code})
                              </MenuItem>
                            ))}
                          </Select>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <TextField
                  label="Email"
                  value={email}
                  onChange={handleEmailChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  fullWidth
                />
              </Box>
            </Box>

            <Box sx={{ marginTop: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button variant="contained" onClick={addProfileDetails} disabled={!isFormValid() || mobile.length !== selectedPhoneLength}>
                Submit
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default CreateProfile;
