import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Button,
  Container,
  Chip,
  IconButton,
  Tooltip,
  useTheme,
  Skeleton,
  Alert,
  Dialog,
  DialogContent,
  Fade,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCardsData } from '../../redux/actions/allCardsDataActions';
import {
  Download,
  Edit,
  Payment,
  AccessTime,
  CheckCircle,
  ErrorOutline,
  Close,
  PlayArrow,
  Pause,
  Fullscreen,
} from '@mui/icons-material';
import 'animate.css';
import { useTheme as useAppTheme } from '../../theme/ThemeContext';
import Loading from '../../components/Loading';

const CardStatusChip = ({ status, estimatedTime }) => {
  const getStatusColor = () => {
    switch (status) {
      case 'ready':
        return { bg: '#4caf50', color: 'white' };
      case 'processing':
        return { bg: '#ff9800', color: 'black' };
      default:
        return { bg: '#e0e0e0', color: 'black' };
    }
  };

  const { bg, color } = getStatusColor();

  return (
    <Chip
      icon={status === 'ready' ? <CheckCircle /> : <AccessTime />}
      label={
        status === 'ready'
          ? 'Ready'
          : `Processing (${estimatedTime}m)`
      }
      size="small"
      sx={{
        bgcolor: bg,
        color: color,
        fontWeight: 500,
        fontSize: { xs: '0.75rem', sm: '0.875rem' },
        height: { xs: 24, sm: 32 },
        '& .MuiChip-icon': {
          color: color,
          fontSize: { xs: '1rem', sm: '1.25rem' },
        },
      }}
    />
  );
};

const MediaViewer = ({ open, onClose, media, type = 'image' }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = React.useRef(null);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      TransitionComponent={Fade}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      PaperProps={{
        sx: {
          bgcolor: 'background.paper',
          maxHeight: '90vh',
          m: 2,
          position: 'relative',
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'white',
          bgcolor: 'rgba(0, 0, 0, 0.5)',
          '&:hover': {
            bgcolor: 'rgba(0, 0, 0, 0.7)',
          },
          zIndex: 1,
        }}
      >
        <Close />
      </IconButton>

      <DialogContent sx={{ p: 0, overflow: 'hidden', aspectRatio: '16/9' }}>
        {type === 'video' ? (
          <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
            <video
              ref={videoRef}
              src={media}
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              controls
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
            />
            <Box
              sx={{
                position: 'absolute',
                bottom: 16,
                left: '50%',
                transform: 'translateX(-50%)',
                display: 'flex',
                gap: 2,
                bgcolor: 'rgba(0, 0, 0, 0.5)',
                borderRadius: 2,
                p: 1,
              }}
            >
              <IconButton
                onClick={handlePlayPause}
                sx={{ color: 'white' }}
              >
                {isPlaying ? <Pause /> : <PlayArrow />}
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box
            component="img"
            src={media}
            alt="Full screen view"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

const CardActions = ({ card, isDarkMode }) => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = card.final_url;
    link.target = '_blank';
    link.download = 'invitation.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' },
        gap: 1, 
        justifyContent: { xs: 'stretch', sm: 'flex-end' }, 
        mt: 2,
        '& .MuiButton-root': {
          width: { xs: '100%', sm: 'auto' },
          py: { xs: 1, sm: 'inherit' },
        }
      }}
    >
      {card?.editCounter > 0 && (
        <Tooltip title="Edit your card">
          <Button
            variant="outlined"
            startIcon={<Edit />}
            sx={{
              borderRadius: 2,
              textTransform: 'none',
              borderColor: '#fdd835',
              color: 'black',
              '&:hover': {
                borderColor: '#fbc02d',
                bgcolor: 'rgba(253, 216, 53, 0.1)',
              },
            }}
          >
            Edit Card
          </Button>
        </Tooltip>
      )}

      {card?.payment_status === 'Pending' && (
        <Tooltip title="Complete payment">
          <Button
            variant="contained"
            startIcon={<Payment />}
            sx={{
              borderRadius: 2,
              textTransform: 'none',
              bgcolor: '#ff4081',
              '&:hover': {
                bgcolor: '#f50057',
              },
            }}
          >
            Pay Now
          </Button>
        </Tooltip>
      )}

      {card?.final_url && (
        <Tooltip title="Download your card">
          <Button
            variant="contained"
            startIcon={<Download />}
            onClick={handleDownload}
            sx={{
              borderRadius: 2,
              textTransform: 'none',
              bgcolor: isDarkMode ? '#424242' : '#b71c1c',
              '&:hover': {
                bgcolor: isDarkMode ? '#616161' : '#d32f2f',
              },
            }}
          >
            Download
          </Button>
        </Tooltip>
      )}
    </Box>
  );
};

const ProcessingPlaceholder = () => (
  <Box
    sx={{
      height: { xs: 140, sm: 180 },
      width: '100%',
      borderRadius: 1,
      bgcolor: (theme) => theme.palette.mode === 'dark' ? 'grey.800' : 'grey.100',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: { xs: 1, sm: 2 },
      p: { xs: 2, sm: 3 },
    }}
  >
    <CircularProgress size={32} />
    <Typography 
      variant="body2" 
      color="text.secondary"
      textAlign="center"
      sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
    >
      Processing your invitation...
    </Typography>
  </Box>
);

const MyCards = () => {
  const theme = useTheme();
  const { isDarkMode } = useAppTheme();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const limit = 8;

  const cardData = useSelector((state) => state?.cardData?.cardsData?.data);

  const handleMediaClick = (media) => {
    setSelectedMedia(media);
  };

  const handleCloseMedia = () => {
    setSelectedMedia(null);
  };

  useEffect(() => {
    const fetchCardsData = async () => {
      setLoading(true);
      try {
        const rsp = await dispatch(getAllCardsData(page, limit));
        const total = rsp?.data?.total;
        const pages = Math.ceil(total / Number(limit));
        setPage(pages);
      } catch (error) {
        console.error('Error fetching cards:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchCardsData();
  }, [dispatch, page, limit]);

  if (loading) {
    return (
      <Container 
        maxWidth="lg" 
        sx={{ 
          px: { xs: 0, sm: 2 },
          mx: 0,
          width: '100%',
          maxWidth: '100%' 
        }}
      >
        <Box sx={{ mb: 2, px: { xs: 1, sm: 0 } }}>
          <Skeleton variant="text" width={200} height={32} />
        </Box>
        <Grid container spacing={{ xs: 1, sm: 2 }}>
          {[1, 2].map((item) => (
            <Grid item xs={12} key={item}>
              <Skeleton variant="rectangular" height={200} sx={{ borderRadius: { xs: 0, sm: 2 } }} />
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  }

  return (
    <Container 
      maxWidth="lg" 
      sx={{ 
        px: { xs: 0, sm: 2 },
        mx: 0,
        width: '100%',
        maxWidth: '100%' 
      }}
    >
      <Box sx={{ mb: { xs: 2, sm: 4 }, px: { xs: 1, sm: 0 } }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            color: isDarkMode ? 'grey.100' : 'grey.900',
            fontSize: { xs: '1.5rem', sm: '2rem' },
          }}
        >
          My Saved Cards
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: isDarkMode ? 'grey.400' : 'grey.600',
            mt: 0.5,
            fontSize: { xs: '0.875rem', sm: '1rem' },
          }}
        >
          View and manage all your generated invitations
        </Typography>
      </Box>

      {cardData?.rows?.length > 0 ? (
        <Grid container spacing={{ xs: 0, sm: 2 }}>
          {cardData.rows.map((card, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card
                elevation={isDarkMode ? 2 : 1}
                sx={{
                  height: '100%',
                  bgcolor: isDarkMode ? 'grey.900' : 'background.paper',
                  borderRadius: { xs: 0, sm: 2 },
                  overflow: 'hidden',
                  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                  '&:hover': {
                    transform: { xs: 'none', sm: 'translateY(-4px)' },
                    boxShadow: { xs: 0, sm: 6 },
                  },
                  mb: { xs: 1, sm: 0 },
                }}
              >
                <CardContent sx={{ p: { xs: 1.5, sm: 2 } }}>
                  <Box sx={{ mb: 1.5 }}>
                    <CardStatusChip
                      status={card?.status}
                      estimatedTime={card?.estimated_time || 5}
                    />
                  </Box>

                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      mb: 1.5,
                      color: isDarkMode ? 'grey.100' : 'grey.900',
                      fontSize: { xs: '1rem', sm: '1.25rem' },
                    }}
                  >
                    {card?.title || 'Untitled Invitation'}
                  </Typography>

                  {!card?.thumbnail ? (
                    <ProcessingPlaceholder />
                  ) : (
                    <Box
                      sx={{
                        position: 'relative',
                        height: { xs: 140, sm: 180 },
                        width: '100%',
                        borderRadius: 1,
                        overflow: 'hidden',
                        cursor: 'pointer',
                        '&:hover': {
                          '& .media-overlay': {
                            opacity: 1,
                          },
                        },
                      }}
                      onClick={() => handleMediaClick(card?.thumbnail)}
                    >
                      <CardMedia
                        component="img"
                        sx={{
                          height: '100%',
                          width: '100%',
                          objectFit: 'cover',
                        }}
                        image={card?.thumbnail}
                        alt={card?.title || 'Invitation Preview'}
                      />
                      <Box
                        className="media-overlay"
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          bgcolor: 'rgba(0, 0, 0, 0.3)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          opacity: { xs: 1, sm: 0 },
                          transition: 'opacity 0.2s ease-in-out',
                        }}
                      >
                        <Fullscreen sx={{ color: 'white', fontSize: { xs: 24, sm: 32 } }} />
                      </Box>
                    </Box>
                  )}

                  <CardActions card={card} isDarkMode={isDarkMode} />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Alert
          severity="info"
          sx={{
            borderRadius: { xs: 0, sm: 2 },
            bgcolor: isDarkMode ? 'grey.900' : 'inherit',
            p: { xs: 1.5, sm: 2 },
            mx: { xs: 1, sm: 0 },
          }}
        >
          <Typography sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
            You haven't created any cards yet. Start by browsing our collection and creating your first invitation!
          </Typography>
        </Alert>
      )}

      <MediaViewer
        open={Boolean(selectedMedia)}
        onClose={handleCloseMedia}
        media={selectedMedia}
        type={selectedMedia?.endsWith('.mp4') ? 'video' : 'image'}
      />
    </Container>
  );
};

export default MyCards;