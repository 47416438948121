import React, { useState, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
  ListItemButton,
  IconButton,
  Drawer,
  ListItemIcon,
  Typography,
  Tooltip,
  Badge,
  Avatar,
  AppBar,
  Toolbar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import CategoryIcon from '@mui/icons-material/Category';
import { Rsvp, ChevronLeft, ChevronRight } from '@mui/icons-material';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const categories = [
  { label: 'My saved Cards', path: '/dashboard/my-cards', icon: 'HistoryEduIcon', badge: 0 },
  { label: 'Digital E-invites', path: '/dashboard/digital-cards', icon: 'rsvp', badge: 2 },
  { label: 'My Profile', path: '/dashboard/my-profile', icon: 'AccountCircleIcon', badge: 0 },
  { label: 'Invoices', path: '/dashboard/invoices', icon: 'ReceiptIcon', badge: 1 },
  { label: 'Support Ticket', path: '/support-ticket', icon: 'SupportAgentIcon', badge: 0 },
];

const Dashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLaptopOrAbove = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setIsSidebarOpen(false);
    }
  }, [isMobile]);

  const handleMobileDrawerToggle = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  const closeDrawerAndNavigate = (path) => {
    if (isMobile) {
      setIsMobileSidebarOpen(false);
    }
    navigate(path);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const getIcon = (label) => {
    switch (label) {
      case 'home':
        return <HomeIcon />;
      case 'rsvp':
        return <Rsvp />;
      case 'HistoryEduIcon':
        return <HistoryEduIcon />;
      case 'AccountCircleIcon':
        return <AccountCircleIcon />;
      case 'ReceiptIcon':
        return <ReceiptIcon />;
      case 'SupportAgentIcon':
        return <SupportAgentIcon />;
      default:
        return <CategoryIcon />;
    }
  };

  const sidebarContent = (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          p: { xs: 1.5, sm: 2 },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${theme.palette.divider}`,
          minHeight: { xs: 56, sm: 64 },
        }}
      >
        {(isSidebarOpen || isMobile) && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Avatar
              sx={{
                bgcolor: theme.palette.primary.main,
                width: { xs: 32, sm: 40 },
                height: { xs: 32, sm: 40 },
              }}
            >
              R
            </Avatar>
            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 600,
                fontSize: { xs: '1rem', sm: '1.25rem' },
              }}
            >
              Raj Offset
            </Typography>
          </Box>
        )}
        {isLaptopOrAbove && !isMobile && (
          <IconButton onClick={toggleSidebar} size="small">
            {isSidebarOpen ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        )}
      </Box>

      <List sx={{ flexGrow: 1, pt: { xs: 1, sm: 2 }, px: { xs: 1, sm: 0 } }}>
        {categories.map((category, index) => {
          const isSelected = location.pathname === category.path;
          return (
            <React.Fragment key={index}>
              <ListItem disablePadding>
                <Tooltip title={!isSidebarOpen && !isMobile ? category.label : ''} placement="right">
                  <ListItemButton
                    onClick={() => closeDrawerAndNavigate(category.path)}
                    sx={{
                      minHeight: { xs: 44, sm: 48 },
                      px: { xs: 1.5, sm: 2.5 },
                      borderRadius: 1,
                      mb: 0.5,
                      ...(isSelected && {
                        bgcolor: 'rgba(0, 0, 0, 0.04)',
                        '&:hover': {
                          bgcolor: 'rgba(0, 0, 0, 0.08)',
                        },
                      }),
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: (isSidebarOpen || isMobile) ? { xs: 2, sm: 3 } : 'auto',
                        justifyContent: 'center',
                        color: isSelected ? theme.palette.primary.main : 'inherit',
                        '& .MuiSvgIcon-root': {
                          fontSize: { xs: '1.25rem', sm: '1.5rem' },
                        },
                      }}
                    >
                      <Badge 
                        badgeContent={category.badge} 
                        color="error"
                        sx={{
                          '& .MuiBadge-badge': {
                            fontSize: { xs: '0.65rem', sm: '0.75rem' },
                            height: { xs: 16, sm: 18 },
                            minWidth: { xs: 16, sm: 18 },
                          },
                        }}
                      >
                        {getIcon(category.icon)}
                      </Badge>
                    </ListItemIcon>
                    {(isSidebarOpen || isMobile) && (
                      <ListItemText
                        primary={category.label}
                        sx={{
                          '& .MuiTypography-root': {
                            fontSize: { xs: '0.875rem', sm: '1rem' },
                            fontWeight: isSelected ? 600 : 400,
                          },
                          color: isSelected ? theme.palette.primary.main : 'inherit',
                        }}
                      />
                    )}
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', bgcolor: 'background.default' }}>
      {isMobile ? (
        <>
          <AppBar 
            position="fixed" 
            color="inherit" 
            elevation={1}
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
              bgcolor: 'background.default',
            }}
          >
            <Toolbar sx={{ minHeight: { xs: 56, sm: 64 }, px: { xs: 1, sm: 2 } }}>
              <IconButton
                color="inherit"
                aria-label="open menu"
                edge="start"
                onClick={handleMobileDrawerToggle}
                sx={{
                  mr: 1,
                  p: { xs: 0.5, sm: 1 },
                }}
              >
                <MenuIcon sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }} />
              </IconButton>
              <Typography 
                variant="h6" 
                sx={{ 
                  fontSize: { xs: '1rem', sm: '1.25rem' },
                  fontWeight: 600,
                }}
              >
                Raj Offset
              </Typography>
            </Toolbar>
          </AppBar>

          <Drawer
            variant="temporary"
            anchor="left"
            open={isMobileSidebarOpen}
            onClose={handleMobileDrawerToggle}
            ModalProps={{ keepMounted: true }}
            sx={{
              '& .MuiDrawer-paper': {
                width: { xs: '85%', sm: 280 },
                boxSizing: 'border-box',
                border: 'none',
                boxShadow: theme.shadows[8],
              },
            }}
          >
            {sidebarContent}
          </Drawer>

          <Box 
            component="main" 
            sx={{ 
              flexGrow: 1,
              pt: '56px',
              px: 0,
              width: '100%',
            }}
          >
            <Outlet />
          </Box>
        </>
      ) : (
        <>
          <Drawer
            variant="permanent"
            sx={{
              width: isSidebarOpen ? 280 : 73,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: isSidebarOpen ? 280 : 73,
                boxSizing: 'border-box',
                border: 'none',
                boxShadow: theme.shadows[2],
                transition: theme.transitions.create('width', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
                overflowX: 'hidden',
              },
            }}
          >
            {sidebarContent}
          </Drawer>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              px: { xs: 0, sm: 3 },
              py: { xs: 2, sm: 3 },
              width: { xs: '100%', sm: 'auto' },
              transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }}
          >
            <Outlet />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Dashboard;
