import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Box, CircularProgress, InputAdornment, Select, MenuItem, Radio, FormControlLabel, FormControl, FormLabel, RadioGroup } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LogIn } from '../redux/actions/authActions';
import countries from "../components/countries.json"
import styled from '@emotion/styled';

const Login = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmitNumber = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await dispatch(LogIn({ mobile: mobileNumber, tenant: "rajoffset", country_code: selectedCountry?.dial_code }));
      if (response && response.data && response.data.RefId) {
        navigate(`/verify-otp`, {
          state: {
            referenceId: response.data.RefId,
            mobileNumber: response.data.mobile,
            tenant: response.data.tenant
          }
        });
      } else {
        setError('Please refresh your browser.');
      }
    } catch (err) {
      setError('Failed to send OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  //countries change
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [selectedPhoneLength, setSelectedPhoneLength] = useState(countries[0].phoneLength);

  const handleCountryChange = (event) => {
    const country = countries.find((c) => c.dial_code === event.target.value);
    setSelectedCountry(country);
    setSelectedPhoneLength(country?.phoneLength);
  }
  const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: 12,
    padding: '12px',
    fontSize: '1rem',
    textTransform: 'none',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',
    },
  }));



  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 8, boxShadow: `
    0 0 10px rgba(123, 94, 255, 0.3),
    0 0 20px rgba(69, 202, 255, 0.2),
    0 0 30px rgba(255, 27, 107, 0.1)
  `,

        background: 'rgba(255, 255, 255, 0.95)',
        backdropFilter: 'blur(10px)', borderRadius: '10px', padding: '15px'
      }}>
        <Typography component="h1" variant="h5" sx={{ paddingBottom: '15px', paddingTop: '15px' }}>Login/Register using OTP</Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Mobile Number (WhatsApp Preferred)"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Select
                  value={selectedCountry?.flag}
                  onChange={handleCountryChange}
                  renderValue={() => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={selectedCountry?.flag}
                        alt={selectedCountry?.name}
                        style={{ width: 24, height: 16, marginRight: 8 }}
                      />
                      {selectedCountry?.dial_code}
                    </div>
                  )}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        boxShadow: 'none',
                        border: 'none',
                        mt: 1,
                        "& .MuiMenuItem-root": {
                          padding: '8px 16px',
                        }
                      }
                    }
                  }}
                  sx={{
                    border: 'none',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 'none',
                    },
                  }}
                >
                  {countries.map((country) => (
                    <MenuItem key={country.dial_code} value={country.dial_code}>
                      <img
                        src={country.flag}
                        alt={country.name}
                        style={{ width: 24, height: 16, marginRight: 8 }}
                      />
                      {country.name} ({country.dial_code})
                    </MenuItem>
                  ))}
                </Select>
              </InputAdornment>
            ),
          }}
        />

        {/* <FormControl component="fieldset" sx={{ mt: 3, width: '100%' }}>
          <FormLabel component="legend" sx={{ mb: 1, fontWeight: 500 }}>
            OTP Delivery Method
          </FormLabel>
          <RadioGroup
            row={!mobileNumber}
            value={otpMethod}
            onChange={(e) => setOtpMethod(e.target.value)}
            sx={{
              justifyContent: 'space-between',
              '& .MuiFormControlLabel-root': {
                mr: mobileNumber ? 0 : 2,
              },
            }}
          >
            <FormControlLabel
              value="sms"
              control={<Radio color="primary" />}
              label="SMS"
              sx={{ flex: 1 }}
            />
            <FormControlLabel
              value="call"
              control={<Radio color="primary" />}
              label="Call"
              sx={{ flex: 1 }}
            />
            <FormControlLabel
              value="whatsapp"
              control={<Radio color="primary" />}
              label="WhatsApp"
              sx={{ flex: 1 }}
            />
            <FormControlLabel
              value="email"
              control={<Radio color="primary" />}
              label="Email"
              sx={{ flex: 1 }}
            />
          </RadioGroup>
        </FormControl> */}

        <StyledButton
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmitNumber}
          disabled={mobileNumber.length !== selectedPhoneLength || isNaN(mobileNumber)}
          sx={{ marginBottom: '16px', marginTop: '16px' }}
        >
          {loading ? <CircularProgress size={24} /> : 'Send OTP'}
        </StyledButton>
        {error && <Typography color="error">{error}</Typography>}
      </Box>
    </Container>
  );
};

export default Login;
