import React from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TimerIcon from '@mui/icons-material/Timer';
import PrintIcon from '@mui/icons-material/Print';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import PaymentIcon from '@mui/icons-material/Payment';
import InfoIcon from '@mui/icons-material/Info';
import ReceiptIcon from '@mui/icons-material/Receipt';
import GavelIcon from '@mui/icons-material/Gavel';

function RefundPolicy() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const policies = [
    {
      title: "Digital Invitation Purchase",
      icon: ReceiptIcon,
      items: [
        {
          condition: "Within 48 hours & Service Not Used",
          description: "You may be able to get a refund depending on the details of the purchase."
        },
        {
          condition: "Service Used",
          description: "No Refund."
        }
      ]
    },
    {
      title: "Physical Invitation Purchase (Printed Cards)",
      icon: PrintIcon,
      items: [
        {
          condition: "Within 48 hours & Printing Not Started",
          description: "You may be able to get a refund of maximum 95% depending on the details of the purchase."
        },
        {
          condition: "Within 12 hours & Printing Not Started",
          description: "You may be able to get a refund depending on the details of the purchase."
        },
        {
          condition: "Printing Started/completed",
          description: "You may be able to get a refund maximum 25% depending on the details of the purchase."
        }
      ]
    },
    {
      title: "Subscriptions",
      icon: SubscriptionsIcon,
      items: [
        {
          condition: "Regular Subscriptions",
          description: "Subscription started can not be refunded for the current billing period. Your Subscription can be stopped from the next billing cycle."
        },
        {
          condition: "Stopped due to Govt/Court Guidelines",
          description: "Is non refundable except it is stated in court decision."
        }
      ]
    }
  ];

  return (
    <Box sx={{ bgcolor: 'background.default', py: 6 }}>
      <Container maxWidth="lg">
        {/* Header Section */}
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <Typography 
            variant="h3" 
            component="h1" 
            gutterBottom
            sx={{ 
              fontWeight: 'bold',
              background: 'linear-gradient(45deg, #FF5722 30%, #FF8A65 90%)',
              backgroundClip: 'text',
              textFillColor: 'transparent',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Refund Policy
          </Typography>
          <Typography variant="h6" color="text.secondary" sx={{ maxWidth: 800, mx: 'auto', mb: 4 }}>
            Our refund policies vary based on the type of purchase. Please review the details below.
          </Typography>
          <Divider sx={{ maxWidth: 100, mx: 'auto', mb: 6 }} />
        </Box>

        {/* Important Notice */}
        <Paper 
          elevation={3} 
          sx={{ 
            p: 3, 
            mb: 4, 
            borderRadius: 2,
            background: 'linear-gradient(45deg, #FF5722 30%, #FF8A65 90%)',
            color: 'white'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <InfoIcon sx={{ fontSize: 40 }} />
            <Typography variant="h6">
              Important: All refunds are subject to payment gateway charges deduction
            </Typography>
          </Box>
        </Paper>

        {/* Policy Sections */}
        {isMobile ? (
          // Mobile View - Accordions
          <Box>
            {policies.map((policy, index) => (
              <Accordion 
                key={index}
                sx={{ 
                  mb: 2,
                  '&:before': { display: 'none' },
                  borderRadius: 2,
                  overflow: 'hidden'
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ 
                    bgcolor: 'background.paper',
                    borderBottom: '1px solid',
                    borderColor: 'divider'
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <policy.icon color="primary" />
                    <Typography variant="h6">{policy.title}</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {policy.items.map((item, itemIndex) => (
                      <ListItem key={itemIndex} sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography variant="subtitle1" color="primary" gutterBottom>
                          {item.condition}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {item.description}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        ) : (
          // Desktop View - Cards
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: 4 }}>
            {policies.map((policy, index) => (
              <Card 
                key={index} 
                elevation={3}
                sx={{
                  height: '100%',
                  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 6,
                  },
                  borderRadius: 2
                }}
              >
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                    <Box
                      sx={{
                        bgcolor: 'primary.main',
                        borderRadius: '50%',
                        p: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <policy.icon sx={{ fontSize: 24, color: 'white' }} />
                    </Box>
                    <Typography variant="h6" component="h2">
                      {policy.title}
                    </Typography>
                  </Box>
                  <Divider sx={{ mb: 2 }} />
                  <List>
                    {policy.items.map((item, itemIndex) => (
                      <ListItem key={itemIndex} sx={{ flexDirection: 'column', alignItems: 'flex-start', px: 0 }}>
                        <Typography variant="subtitle1" color="primary" gutterBottom>
                          {item.condition}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {item.description}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            ))}
          </Box>
        )}

        {/* Additional Information */}
        <Paper elevation={1} sx={{ mt: 4, p: 3, borderRadius: 2 }}>
          <Typography variant="body2" color="text.secondary">
            Note: For specific refund inquiries or special cases, please contact our support team through WhatsApp or email.
            We handle each case with careful consideration of the circumstances.
          </Typography>
        </Paper>
      </Container>
    </Box>
  );
}

export default RefundPolicy;