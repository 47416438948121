import React, { useState } from 'react';
import {
    Box,
    Container,
    Typography,
    Card,
    CardContent,
    Stack,
    Button,
    IconButton,
    Chip,
    useTheme,
    useMediaQuery,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
} from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PaidIcon from '@mui/icons-material/Paid';
import PendingIcon from '@mui/icons-material/Pending';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const Invoices = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [invoices, setInvoices] = useState([]); // Initialize as empty array

    const getStatusColor = (status) => {
        switch (status) {
            case 'paid':
                return {
                    bg: theme.palette.mode === 'dark' 
                        ? 'rgba(46, 125, 50, 0.2)' 
                        : 'rgba(76, 175, 80, 0.1)',
                    color: theme.palette.mode === 'dark' 
                        ? '#66bb6a' 
                        : '#2e7d32',
                    icon: <PaidIcon fontSize="small" />,
                };
            case 'pending':
                return {
                    bg: theme.palette.mode === 'dark' 
                        ? 'rgba(237, 108, 2, 0.2)' 
                        : 'rgba(255, 152, 0, 0.1)',
                    color: theme.palette.mode === 'dark' 
                        ? '#ffa726' 
                        : '#ed6c02',
                    icon: <PendingIcon fontSize="small" />,
                };
            default:
                return {
                    bg: theme.palette.grey[200],
                    color: theme.palette.text.secondary,
                    icon: null,
                };
        }
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
        }).format(amount);
    };

    const handleViewInvoice = (invoice) => {
        setSelectedInvoice(invoice);
    };

    const handleCloseInvoice = () => {
        setSelectedInvoice(null);
    };

  return (
        <Container 
            maxWidth="lg" 
            disableGutters={isMobile}
            sx={{ 
                px: { xs: 0, sm: 2 },
                py: { xs: 0, sm: 3 },
                minHeight: '100vh',
                background: theme.palette.mode === 'dark'
                    ? 'linear-gradient(135deg, rgba(40, 40, 80, 0.95), rgba(30, 30, 60, 0.85))'
                    : 'linear-gradient(135deg, rgba(248, 250, 255, 0.95), rgba(240, 245, 255, 0.85))',
            }}
        >
            <Box 
                sx={{ 
                    px: { xs: 2, sm: 3 }, 
                    py: { xs: 2, sm: 3 },
                    background: theme.palette.mode === 'dark'
                        ? 'linear-gradient(45deg, #1e88e5 10%, #1565c0 90%)'
                        : 'linear-gradient(45deg, #2196f3 10%, #1976d2 90%)',
                    borderRadius: { xs: 0, sm: 2 },
                    mb: 3,
                    position: 'relative',
                    overflow: 'hidden',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'radial-gradient(circle at top right, rgba(255,255,255,0.2) 0%, transparent 70%)',
                    },
                }}
            >
                <Typography 
                    variant="h5" 
                    sx={{ 
                        fontWeight: 700,
                        fontSize: { xs: '1.5rem', sm: '1.75rem' },
                        color: '#fff',
                        textAlign: 'center',
                        textShadow: '2px 2px 4px rgba(0,0,0,0.2)',
                        letterSpacing: '0.5px',
                        position: 'relative',
                        zIndex: 1,
                    }}
                >
                    <ReceiptLongIcon sx={{ mr: 1, verticalAlign: 'bottom' }} />
                    My Invoices
                </Typography>
            </Box>

            {/* Empty State */}
            <Box 
                sx={{ 
                    p: { xs: 4, sm: 5 },
                    textAlign: 'center',
                    background: theme.palette.mode === 'dark'
                        ? 'linear-gradient(135deg, rgba(30, 136, 229, 0.15) 0%, rgba(21, 101, 192, 0.25) 100%)'
                        : 'linear-gradient(135deg, rgba(33, 150, 243, 0.1) 0%, rgba(25, 118, 210, 0.2) 100%)',
                    borderRadius: { xs: 0, sm: 2 },
                    border: `1px solid ${theme.palette.mode === 'dark' 
                        ? 'rgba(255,255,255,0.1)' 
                        : 'rgba(25, 118, 210, 0.2)'}`,
                }}
            >
                <DescriptionOutlinedIcon 
                    sx={{ 
                        fontSize: '100px', 
                        color: theme.palette.mode === 'dark' 
                            ? 'rgba(144, 202, 249, 0.2)' 
                            : 'rgba(25, 118, 210, 0.2)',
                        mb: 3,
                    }} 
                />
                <Typography 
                    sx={{ 
                        color: theme.palette.mode === 'dark' 
                            ? '#90caf9' 
                            : '#1976d2',
                        fontSize: { xs: '1rem', sm: '1.25rem' },
                        fontWeight: 500,
                        mb: 1,
                    }}
                >
                    No Invoices Yet
                </Typography>
                <Typography
                    color="text.secondary"
                    sx={{ 
                        fontSize: { xs: '0.875rem', sm: '1rem' },
                        maxWidth: '500px',
                        mx: 'auto',
                    }}
                >
                    All your invoices will be available here when they are ready. 
                    You'll be notified when new invoices are generated.
                </Typography>
            </Box>

            {/* Invoice Details Dialog */}
            <Dialog
                open={Boolean(selectedInvoice)}
                onClose={handleCloseInvoice}
                maxWidth="sm"
                fullWidth
                fullScreen={isMobile}
                PaperProps={{
                    sx: {
                        borderRadius: { xs: 0, sm: 2 },
                        m: { xs: 0, sm: 2 },
                    },
                }}
            >
                {selectedInvoice && (
                    <>
                        <DialogTitle sx={{ px: { xs: 2, sm: 3 }, pt: { xs: 2, sm: 3 } }}>
                            <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                Invoice Details
                            </Typography>
                        </DialogTitle>
                        <DialogContent sx={{ px: { xs: 2, sm: 3 }, pb: { xs: 2, sm: 3 } }}>
                            <Stack spacing={2}>
                                <Box>
                                    <Typography variant="overline" color="text.secondary">
                                        Invoice Number
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                        {selectedInvoice.id}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="overline" color="text.secondary">
                                        Date
                                    </Typography>
                                    <Typography variant="body1">
                                        {new Date(selectedInvoice.date).toLocaleDateString('en-US', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                        })}
                                    </Typography>
                                </Box>
                                <Divider />
                                <Box>
                                    <Typography variant="overline" color="text.secondary" sx={{ mb: 1, display: 'block' }}>
                                        Items
                                    </Typography>
                                    <Stack spacing={1}>
                                        {selectedInvoice.items.map((item, index) => (
                                            <Box 
                                                key={index}
                                                sx={{ 
                                                    display: 'flex', 
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Box>
                                                    <Typography variant="body1">
                                                        {item.name}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Quantity: {item.quantity}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                                    {formatCurrency(item.price * item.quantity)}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Stack>
                                </Box>
                                <Divider />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="h6">Total Amount</Typography>
                                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                        {formatCurrency(selectedInvoice.amount)}
                                    </Typography>
                                </Box>
                            </Stack>
                        </DialogContent>
                        <DialogActions sx={{ px: { xs: 2, sm: 3 }, pb: { xs: 2, sm: 3 } }}>
                            <Button 
                                onClick={handleCloseInvoice}
                                variant="outlined"
                                sx={{ mr: 1 }}
                            >
                                Close
                            </Button>
                            <Button 
                                variant="contained"
                                startIcon={<FileDownloadIcon />}
                            >
                                Download PDF
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </Container>
    );
};

export default Invoices;