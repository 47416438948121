import React from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Avatar,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  Celebration,
  EmojiEvents,
  Favorite,
  LocalOffer,
} from '@mui/icons-material';

const WelcomeCard = ({ icon: Icon, title, description }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card
      elevation={0}
      sx={{
        height: '100%',
        borderRadius: { xs: 1, sm: 2 },
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: { xs: 'none', sm: 'translateY(-5px)' },
        },
        bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'white',
        border: `1px solid ${isDarkMode ? 'rgba(255, 255, 255, 0.1)' : theme.palette.divider}`,
      }}
    >
      <CardContent 
        sx={{ 
          height: '100%', 
          display: 'flex', 
          flexDirection: 'column',
          p: { xs: 2, sm: 3 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: { xs: 1.5, sm: 2 },
          }}
        >
          <Avatar
            sx={{
              bgcolor: isDarkMode ? 'primary.dark' : 'primary.light',
              color: 'primary.main',
              mr: 2,
              width: { xs: 32, sm: 40 },
              height: { xs: 32, sm: 40 },
              '& .MuiSvgIcon-root': {
                fontSize: { xs: '1.25rem', sm: '1.5rem' },
              },
            }}
          >
            <Icon />
          </Avatar>
          <Typography 
            variant="h6" 
            sx={{ 
              fontWeight: 600, 
              color: isDarkMode ? 'grey.300' : 'grey.900',
              fontSize: { xs: '1rem', sm: '1.25rem' },
            }}
          >
            {title}
          </Typography>
        </Box>
        <Typography
          variant="body1"
          sx={{
            color: isDarkMode ? 'grey.400' : 'grey.600',
            flexGrow: 1,
            fontSize: { xs: '0.875rem', sm: '1rem' },
            lineHeight: { xs: 1.4, sm: 1.6 },
          }}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

const CompleteProfile = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const welcomeCards = [
    {
      icon: Celebration,
      title: 'Welcome to RajOffset',
      description: 'Your one-stop destination for beautiful digital invitations and cards.',
    },
    {
      icon: EmojiEvents,
      title: 'Premium Quality',
      description: 'Explore our collection of high-quality designs crafted just for you.',
    },
    {
      icon: Favorite,
      title: 'Personalized Experience',
      description: 'Create and customize invitations that reflect your unique style.',
    },
    {
      icon: LocalOffer,
      title: 'Special Offers',
      description: 'Get exclusive deals and offers on our premium invitation designs.',
    },
  ];

  return (
    <Container 
      maxWidth="lg" 
      sx={{ 
        px: { xs: 0, sm: 3 },
        py: { xs: 2, sm: 3 },
      }}
    >
      <Box sx={{ mb: { xs: 4, sm: 6 }, textAlign: 'center', px: { xs: 2, sm: 0 } }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            mb: { xs: 1.5, sm: 2 },
            fontSize: { xs: '1.75rem', sm: '2.5rem', md: '3rem' },
            background: isDarkMode
              ? 'linear-gradient(45deg, #FF6B6B 30%, #4ECDC4 90%)'
              : 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            lineHeight: 1.2,
          }}
        >
          Welcome to RajOffset
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: isDarkMode ? 'grey.400' : 'grey.600',
            maxWidth: '600px',
            mx: 'auto',
            fontSize: { xs: '1rem', sm: '1.25rem' },
            lineHeight: { xs: 1.4, sm: 1.6 },
            px: { xs: 2, sm: 0 },
          }}
        >
          Create beautiful invitations and celebrate your special moments with us
        </Typography>
      </Box>

      <Grid 
        container 
        spacing={{ xs: 2, sm: 3 }}
        sx={{ px: { xs: 2, sm: 0 } }}
      >
        {welcomeCards.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <WelcomeCard {...card} />
          </Grid>
        ))}
      </Grid>

      <Box
        sx={{
          mt: { xs: 4, sm: 6 },
          p: { xs: 2.5, sm: 4 },
          borderRadius: { xs: 0, sm: 2 },
          bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(33, 150, 243, 0.05)',
          textAlign: 'center',
          mx: { xs: 0, sm: 'auto' },
        }}
      >
        <Typography 
          variant="h5" 
          gutterBottom 
          sx={{ 
            color: isDarkMode ? 'grey.300' : 'grey.900',
            fontSize: { xs: '1.25rem', sm: '1.5rem' },
            fontWeight: 600,
          }}
        >
          Ready to Get Started?
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: isDarkMode ? 'grey.400' : 'grey.600',
            mb: { xs: 2, sm: 3 },
            maxWidth: '600px',
            mx: 'auto',
            fontSize: { xs: '0.875rem', sm: '1rem' },
            lineHeight: { xs: 1.4, sm: 1.6 },
            px: { xs: 2, sm: 0 },
          }}
        >
          All your generated and saved invitations can be found in the "My Cards" section. Visit now to view your collection and create new invitations.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth={isMobile}
          onClick={() => window.location.href = '/dashboard/my-cards'}
          sx={{
            px: { xs: 3, sm: 4 },
            py: { xs: 1.25, sm: 1.5 },
            borderRadius: { xs: 1, sm: 2 },
            textTransform: 'none',
            fontSize: { xs: '1rem', sm: '1.1rem' },
            maxWidth: { xs: '100%', sm: 'auto' },
          }}
        >
          Browse Cards
        </Button>
      </Box>
    </Container>
  );
};

export default CompleteProfile;