import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const fetchAllCardGroupRequest = () => ({
    type: actionTypes.GET_ALL_CARD_GROUP_REQUEST,
});

export const fetchAllCardGroupSuccess = (data) => ({
    type: actionTypes.GET_ALL_CARD_GROUP_SUCCESS,
    payload: data,
});

export const fetchAllCardGroupFailure = (error) => ({
    type: actionTypes.GET_ALL_CARD_GROUP_FAILURE,
    payload: error,
});

export const fetchAllCardGroupData = () => {
    return async (dispatch) => {
        fetchAllCardGroupRequest();
        try{
            const data = await api.getAllCardGroup();
            console.log("card group in action",data);
            fetchAllCardGroupSuccess(data);
            return data;
        }catch (error){
            fetchAllCardGroupFailure(error);
        }
    };
};