import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

function FontStyle() {

  const fontsArray = ["ParisienneRegular", "AllessaPersonalUs", "AulyarsItalic", "AulyarsRegular", "BigdeyDemo", "BSCalligraphy", "ChopinScript", "ChristmasTimePersonalUse", "GreatDayPersonalUse", "HandycheeraRegular", "LucySaid", "MessengerPigeons", "ILoveGlitter", "Precious", "TheHeartofEverything", "WinterCalligraphy", "Aaleyah", "MotherTongue", "AgendaKing", "AlexBrushRegular", "Andhyta", "BacktoBlackBold", "BubbleLove", "BullettoKilla", "ChocolateBar", "CocogooseNarrowRegularItalic", "FOFBB", "FOFBB_ITALIC", "Freakshow", "FROSTY", "Gaby", "HughisLife", "LoveLetters", "mexcellent3d", "MISTV", "musicals", "MySunshine", "Natyl", "PleaseDonotTakeMyMan", "Regista", "Streetwear", "VirgiEllaRegular", "SaralaBold", "SaralaRegular", "NotoBold", "NotoBoldItalic", "NotoItalic", "NotoRegular", "Alkatra", "BakbakOne", "Modak", "PalanquinDarkBold", "PalanquinDarkMedium", "PalanquinDarkRegular", "PalanquinDarkSemiBold", "TiroDevanagariSanskritItalic", "TiroDevanagariSanskritRegular", "YatraOne"]
    ;

  const [fontSize, setFontSize] = useState(75);
  const [fontText, setFontText] = useState('sample-text');

  const [fontArray, setFontArray] = useState(false);


  const openImage = () => {
    setFontArray(true)
  }
  return (
    <div>
      <Box
        component="form"
        sx={{
          width: '80%',
          margin: 'auto',
          mt: 5,
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRedius: 2,
          boxShadow: '0px 4px 8px',
          mb: 5
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
          <TextField
            label="Font Size"
            type='number'
            onChange={(e) => setFontSize(e.target.value)}
            fullWidth
          />
          <TextField
            label="Font Text"
            type='text'
            onChange={(e) => setFontText(e.target.value)}
            fullWidth
          />
          <Button variant='contained' color='primary' onClick={openImage}>Submit</Button>
        </Box>





      </Box>
      <Box>
      {fontArray ? (
        <Grid container spacing={3}>
        {fontsArray.map((value, index) => (
          <Grid key={index} item xs={12} sm={4}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <img
              src={`https://generator.rajoffset.com/s2s/v1/text2-image?text=${fontText}&fontName=${value}&fontSize=${fontSize}`}
              style={{ width: '100%'}}
              alt='font Style'
            />
            <Typography>{value}</Typography>
            </Box>
            
           
          </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>No Data</Typography>
      )}
      </Box>

     
    </div>
  )
}

export default FontStyle