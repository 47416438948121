import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Container, useTheme } from '@mui/material';
import { Android, Timer } from '@mui/icons-material';

function AndroidAppPage() {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === "dark";
    const targetDate = new Date('2025-02-05T00:00:00');
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    function calculateTimeLeft() {
        const difference = targetDate - new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);
    }, []);

    const TimeUnit = ({ value, label }) => (
        <Paper
            elevation={3}
            sx={{
                p: 2,
                m: 1,
                minWidth: 100,
                textAlign: 'center',
                backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'white',
                borderRadius: 2,
                transition: 'transform 0.3s ease',
                '&:hover': {
                    transform: 'scale(1.05)'
                }
            }}
        >
            <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#3DDC84' }}>
                {value}
            </Typography>
            <Typography variant="subtitle1" sx={{ color: isDarkMode ? 'grey.400' : 'grey.600' }}>
                {label}
            </Typography>
        </Paper>
    );

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: isDarkMode ? 'grey.900' : '#f8f9fa',
                py: 4,
                background: isDarkMode 
                    ? 'linear-gradient(135deg, #1a237e 0%, #000000 100%)'
                    : 'linear-gradient(135deg, #E8F5E9 0%, #ffffff 100%)'
            }}
        >
            <Container maxWidth="md">
                <Box
                    sx={{
                        textAlign: 'center',
                        mb: 6
                    }}
                >
                    <Android 
                        sx={{ 
                            fontSize: 80, 
                            color: '#3DDC84',
                            mb: 2,
                            animation: 'float 3s ease-in-out infinite',
                            '@keyframes float': {
                                '0%': { transform: 'translateY(0px)' },
                                '50%': { transform: 'translateY(-10px)' },
                                '100%': { transform: 'translateY(0px)' }
                            }
                        }} 
                    />
                    <Typography 
                        variant="h2" 
                        gutterBottom
                        sx={{
                            fontWeight: 700,
                            color: isDarkMode ? 'grey.100' : 'grey.900',
                            mb: 3
                        }}
                    >
                        Coming Soon to Android
                    </Typography>
                    <Typography 
                        variant="h5" 
                        sx={{
                            color: isDarkMode ? 'grey.400' : 'grey.600',
                            mb: 4
                        }}
                    >
                        Our Android app is under development and will be available from February 5, 2025
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        gap: 2,
                        mb: 4
                    }}
                >
                    <TimeUnit value={timeLeft.days} label="Days" />
                    <TimeUnit value={timeLeft.hours} label="Hours" />
                    <TimeUnit value={timeLeft.minutes} label="Minutes" />
                    <TimeUnit value={timeLeft.seconds} label="Seconds" />
                </Box>

                <Box
                    sx={{
                        mt: 4,
                        p: 3,
                        borderRadius: 2,
                        backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
                        textAlign: 'center',
                        border: `2px solid ${isDarkMode ? 'rgba(61, 220, 132, 0.3)' : 'rgba(61, 220, 132, 0.1)'}`,
                    }}
                >
                    <Timer sx={{ fontSize: 40, color: '#3DDC84', mb: 2 }} />
                    <Typography variant="body1" sx={{ color: isDarkMode ? 'grey.400' : 'grey.600' }}>
                        Stay tuned! We're working hard to bring you an amazing Android experience.
                        Get ready for seamless invitation creation and management on your Android device.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}

export default AndroidAppPage;