import React from 'react';
import { 
  Box, 
  Grid, 
  Typography, 
  Button, 
  Container, 
  Paper,
  useTheme,
  useMediaQuery,
  IconButton,
  Divider,
  Card,
  CardContent,
  Tooltip
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import FolderIcon from '@mui/icons-material/Folder';
import ChatIcon from '@mui/icons-material/Chat';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const ContactUsPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleWhatsAppOpen = () => {
    window.open("whatsapp://send?abid=918765140002&text=Hi RajOffset Support Team,", "_blank");
  };

  const handleEmailOpen = () => {
    window.location.href = "mailto:support@rajoffset.com";
  };

  const handleMapOpen = () => {
    window.open("https://maps.google.com/?q=Raj+Offset+Jamui+Bazaar+Chunar+Mirzapur", "_blank");
  };

  const ContactCard = ({ icon: Icon, title, description, action, actionText, disabled, tooltip }) => (
    <Card 
      elevation={3}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: 6,
        },
        bgcolor: 'background.paper',
        borderRadius: 2
      }}
    >
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3 }}>
        <Box
          sx={{
            bgcolor: 'primary.main',
            borderRadius: '50%',
            p: 2,
            mb: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Icon sx={{ fontSize: 40, color: 'white' }} />
        </Box>
        <Typography variant="h6" component="h3" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography variant="body1" color="text.secondary" align="center" sx={{ mb: 3, flexGrow: 1 }}>
          {description}
        </Typography>
        <Tooltip title={disabled ? tooltip : ''}>
          <span>
            <Button
              variant="contained"
              onClick={action}
              disabled={disabled}
              startIcon={<Icon />}
              fullWidth
              sx={{
                mt: 'auto',
                textTransform: 'none',
                borderRadius: 2,
                py: 1
              }}
            >
              {actionText}
            </Button>
          </span>
        </Tooltip>
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', py: 6 }}>
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <Typography 
            variant="h3" 
            component="h1" 
            gutterBottom
            sx={{ 
              fontWeight: 'bold',
              background: 'linear-gradient(45deg, #FF5722 30%, #FF8A65 90%)',
              backgroundClip: 'text',
              textFillColor: 'transparent',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Get in Touch
          </Typography>
          <Typography variant="h6" color="text.secondary" sx={{ maxWidth: 600, mx: 'auto', mb: 4 }}>
            We're here to help and answer any question you might have
          </Typography>
          <Divider sx={{ maxWidth: 100, mx: 'auto', mb: 6 }} />
        </Box>

        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <ContactCard
              icon={WhatsAppIcon}
              title="WhatsApp Support"
              description="Get instant support through WhatsApp. Available 24/7 for your queries."
              action={handleWhatsAppOpen}
              actionText="Chat on WhatsApp"
              disabled={false}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <ContactCard
              icon={EmailIcon}
              title="Email Us"
              description="Send us an email and we'll get back to you within 24 hours."
              action={handleEmailOpen}
              actionText="Send Email"
              disabled={false}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <ContactCard
              icon={ChatIcon}
              title="Live Chat"
              description="Chat with our support team in real-time."
              action={() => {}}
              actionText="Start Chat"
              disabled={true}
              tooltip="Coming soon!"
            />
          </Grid>
        </Grid>

        <Paper 
          elevation={3} 
          sx={{ 
            mt: 6, 
            p: 4, 
            borderRadius: 2,
            background: 'linear-gradient(45deg, #FF5722 30%, #FF8A65 90%)',
            color: 'white'
          }}
        >
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <LocationOnIcon sx={{ mr: 2, fontSize: 30 }} />
                <Typography variant="h6">Our Store Location</Typography>
              </Box>
              <Box 
                sx={{ 
                  pl: 5,
                  mb: 3,
                  position: 'relative',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    left: 20,
                    top: 0,
                    bottom: 0,
                    width: 3,
                    background: 'rgba(255, 255, 255, 0.5)',
                    borderRadius: 4
                  }
                }}
              >
                <Typography 
                  variant="h4" 
                  sx={{ 
                    fontWeight: 'bold',
                    mb: 1,
                    textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
                    fontFamily: 'serif',
                    letterSpacing: 1
                  }}
                >
                  Raj Offset
                </Typography>
                <Typography 
                  variant="subtitle1" 
                  sx={{ 
                    opacity: 0.9,
                    fontWeight: 'medium',
                    lineHeight: 1.6
                  }}
                >
                  Jamui Bazaar, Chunar,<br />
                  Mirzapur, Uttar Pradesh,<br />
                  India - 231304
                </Typography>
              </Box>
              <Button
                variant="contained"
                onClick={handleMapOpen}
                startIcon={<LocationOnIcon />}
                sx={{ 
                  ml: 5,
                  bgcolor: 'white',
                  color: 'primary.main',
                  '&:hover': {
                    bgcolor: 'grey.100',
                    transform: 'translateY(-2px)',
                    boxShadow: 3
                  },
                  transition: 'all 0.2s ease-in-out',
                  textTransform: 'none',
                  px: 3,
                  py: 1,
                  borderRadius: 2,
                  boxShadow: 2
                }}
              >
                View on Map
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <AccessTimeIcon sx={{ mr: 2, fontSize: 30 }} />
                <Typography variant="h6">Business Hours</Typography>
              </Box>
              <Typography variant="body1" sx={{ pl: 5 }}>
                Monday - Saturday: 9:30 AM - 6:00 PM
              </Typography>
              <Typography variant="body1" sx={{ pl: 5 }}>
                Sunday: Closed, Limited Service
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

export default ContactUsPage;