import React, { useEffect, useState } from 'react'
import { Box, Card, CardMedia, CardContent, Typography, Grid, Button, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, TextField, DialogActions, DialogContentText, InputAdornment, Select, useTheme, useMediaQuery, Container, Divider, Stack, Fade, Zoom, alpha } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { deleteDigitalCardData, getAllDigitalCardData, sendDigitalInvitationCardData } from '../../redux/actions/digitalCardActions';
import RajOffsetProcessingImage from '../../assets/rajoffset_processing_image.png';
import AddMember from './AddMember';
import Loading from '../../components/Loading';
import countries from "../../components/countries.json"

// Media Viewer Component
const MediaViewer = ({ open, onClose, media, type = 'image' }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = React.useRef(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handlePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xl"
            fullScreen={isMobile}
            TransitionComponent={Zoom}
            PaperProps={{
                sx: {
                    bgcolor: 'background.paper',
                    maxHeight: '90vh',
                    m: { xs: 0, sm: 2 },
                    width: '100%',
                    position: 'relative',
                },
            }}
        >
            <IconButton
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: 'white',
                    bgcolor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1,
                    '&:hover': {
                        bgcolor: 'rgba(0, 0, 0, 0.7)',
                    },
                }}
            >
                <CloseIcon />
            </IconButton>

            <Box sx={{ 
                bgcolor: 'black',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                {type === 'video' ? (
                    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                        <video
                            ref={videoRef}
                            src={media}
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                            controls={false}
                            onPlay={() => setIsPlaying(true)}
                            onPause={() => setIsPlaying(false)}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: 16,
                                left: '50%',
                                transform: 'translateX(-50%)',
                                display: 'flex',
                                gap: 2,
                                bgcolor: 'rgba(0, 0, 0, 0.5)',
                                borderRadius: 2,
                                p: 1,
                            }}
                        >
                            <IconButton onClick={handlePlayPause} sx={{ color: 'white' }}>
                                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>
                        </Box>
                    </Box>
                ) : (
                    <img
                        src={media}
                        alt="Full screen view"
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                        }}
                    />
                )}
            </Box>
        </Dialog>
    );
};

function DigitalCards() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const digitalCard = useSelector((state) => state?.digitalCards?.digitalcardData?.data);
    console.log("digital card", digitalCard);
    const dispatch = useDispatch();
    const [loading, setloading] = useState(false);
    const navigate = useNavigate();

    // fetch data
    // send Digital invitation 
    const [openInvitation, setOpenInvitation] = useState(false);
    const [attendeeName, setAttendeeName] = useState('');
    const [attendeeMobile, setAttendeeMobile] = useState('');
    const [attendeeEmail, setAttendeeEmail] = useState('');
    const [attendeeMessage, setAttendeeMessage] = useState('');
    const [digitalCardId, setDigitalCardId] = useState('');

    useEffect(() => {
        setAttendeeMessage('Invitation with Family');
        const fetchDigitalCard = async () => {
            setloading(true);
            await dispatch(getAllDigitalCardData());
            setloading(false);
        };
        fetchDigitalCard();
    }, [dispatch])

    // menu items 
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const [deleteCardId, setDeleteCardId] = useState();

    // Handle menu open/close
    const handleMenuClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setDeleteCardId(id);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setDeleteCardId(null);
    };

    const handleSendInvitation = (id) => {
        setDigitalCardId(id);
        setOpenInvitation(true);
    };
    const handleSendInvitationClose = () => {
        setOpenInvitation(false);
    };
    const handleSendDigitalInvitation = async (e) => {
        e.preventDefault();
        const data = {
            attendee_name: attendeeName.replaceAll("&", "and"),
            attendee_mobile: attendeeMobile,
            attendee_email: attendeeEmail,
            attendee_message: attendeeMessage.replaceAll("&", "and"),
            id: digitalCardId,
            country_code: selectedCountry,
        };
        setloading(true);
        const response = await sendDigitalInvitationCardData(data);
        console.log("response in digital card", response);
        setloading(false);
        if (response.status === 'success') {
            navigate(`/send-invitation-successfull`);
        }
        handleSendInvitationClose();
    };

    // add member 
    const [openAddMember, setOpenAddMember] = useState(false);

    const handleAddMemberOpen = (id) => {
        setOpenAddMember(true);
        setDigitalCardId(id);
    };
    const closeHandleAddMember = () => {
        setOpenAddMember(false);
    }

    // Delete Card

    const [openDelete, setOpenDelete] = useState(false);

    const handleOpenCardDelete = (card) => {
        setDeleteCardId(card?.id);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };
    const handleDigitalCardDelete = async () => {
        const deletedData = { id: deleteCardId }
        await deleteDigitalCardData(deletedData);
        await dispatch(getAllDigitalCardData());
        handleMenuClose();
        setOpenDelete(false);
    }

    const [emailError, setEmailError] = useState(false);

    const handleTextChange = (e, setState) => {
        const cleanValue = e.target.value.replace(/[^0-9A-Za-z.,()/\-\s]/g, '');
        setState(cleanValue);
    };

    const handleMobileChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    };

    const validateEmail = (e) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmailError(!emailPattern.test(e.target.value));
    };

    //countries change
    const [selectedCountry, setSelectedCountry] = useState(countries[0]);
    const [selectedPhoneLength, setSelectedPhoneLength] = useState(countries[0].phoneLength);

    const handleCountryChange = (event) => {
        const country = countries.find((c) => c.dial_code === event.target.value);
        console.log("country", country);
        setSelectedCountry(country);
        setSelectedPhoneLength(country?.phoneLength);
    };

    console.log("attendee phone length", attendeeMobile.length);
    console.log("selected country", selectedPhoneLength);

    // Add state for media viewer
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [mediaType, setMediaType] = useState('image');

    // Handle media click
    const handleMediaClick = (media, type = 'image') => {
        setSelectedMedia(media);
        setMediaType(type);
    };

    // Handle media close
    const handleMediaClose = () => {
        setSelectedMedia(null);
    };

    return (
        <Container 
            maxWidth="lg" 
            disableGutters={isMobile}
            sx={{ 
                px: { xs: 0, sm: 2 },
                py: { xs: 0, sm: 3 },
                
                minHeight: '100vh',
                position: 'relative',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'radial-gradient(circle at 50% 0%, rgba(103, 58, 183, 0.1), transparent 50%)',
                    pointerEvents: 'none',
                },
            }}
        >
            <Box 
                sx={{ 
                    px: { xs: 2, sm: 3 }, 
                    py: { xs: 2, sm: 3 },
                    background: theme.palette.mode === 'dark'
                        ? 'linear-gradient(45deg, #5c6bc0 10%, #3f51b5 90%), linear-gradient(135deg, rgba(106, 27, 154, 0.4), transparent)'
                        : 'linear-gradient(45deg, #3f51b5 10%, #1a237e 90%), linear-gradient(135deg, rgba(103, 58, 183, 0.4), transparent)',
                    // backgroundBlendMode: 'soft-light',
                    borderRadius: { xs: 0, sm: 2 },
                    mb: 3,
                    position: 'relative',
                    overflow: 'hidden',
                    boxShadow: theme.palette.mode === 'dark'
                        ? '0 4px 20px rgba(63, 81, 181, 0.3), 0 8px 30px rgba(40, 53, 147, 0.2)'
                        : '0 4px 20px rgba(63, 81, 181, 0.3), 0 8px 30px rgba(26, 35, 126, 0.2)',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'radial-gradient(circle at top right, rgba(255,255,255,0.3) 0%, transparent 70%)',
                        mixBlendMode: 'overlay',
                    },
                }}
            >
                <Typography 
                    variant="h5" 
                    sx={{ 
                        fontWeight: 700,
                        fontSize: { xs: '1.5rem', sm: '1.75rem' },
                        color: '#fff',
                        textAlign: 'center',
                        textShadow: '2px 2px 4px rgba(0,0,0,0.2), 0 0 15px rgba(255,255,255,0.3)',
                        letterSpacing: '0.5px',
                        position: 'relative',
                        zIndex: 1,
                    }}
                >
                    Digital Wedding Invitations
            </Typography>
            </Box>

            {loading ? (
                <Loading />
            ) : (
                <Stack spacing={{ xs: 2, sm: 3 }}>
                    {digitalCard?.rows?.length > 0 ? (
                        digitalCard.rows.map((card, index) => (
                            <Card 
                                key={index}
                                elevation={0}
                                sx={{ 
                                    background: theme.palette.mode === 'dark'
                                        ? 'linear-gradient(135deg, rgba(40, 53, 147, 0.15) 0%, rgba(26, 35, 126, 0.25) 100%), linear-gradient(45deg, rgba(103, 58, 183, 0.1), rgba(63, 81, 181, 0.1))'
                                        : 'linear-gradient(135deg, rgba(197, 202, 233, 0.3) 0%, rgba(179, 157, 219, 0.3) 100%), linear-gradient(45deg, rgba(63, 81, 181, 0.1), rgba(103, 58, 183, 0.1))',
                                    backgroundBlendMode: 'overlay',
                                    borderRadius: { xs: 0, sm: 2 },
                                    border: `1px solid ${theme.palette.mode === 'dark' 
                                        ? 'rgba(255,255,255,0.1)' 
                                        : 'rgba(63, 81, 181, 0.2)'}`,
                                    transition: 'all 0.3s ease-in-out',
                                    position: 'relative',
                                    overflow: 'hidden',
                                    '&:hover': {
                                        transform: { xs: 'none', sm: 'translateY(-4px)' },
                                        boxShadow: theme.palette.mode === 'dark'
                                            ? '0 8px 24px rgba(63, 81, 181, 0.2), 0 12px 30px rgba(40, 53, 147, 0.2)'
                                            : '0 8px 24px rgba(63, 81, 181, 0.2), 0 12px 30px rgba(26, 35, 126, 0.2)',
                                    },
                                }}
                            >
                                <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
                                    <Box 
                                        sx={{ 
                                            display: 'flex', 
                                            flexDirection: { xs: 'column', sm: 'row' },
                                            gap: { xs: 2, sm: 3 },
                                        }}
                                    >
                                        {/* Image Section - Moved to top on mobile */}
                                        <Box 
                                            onClick={() => handleMediaClick(card?.thumbnail || RajOffsetProcessingImage)}
                                            sx={{ 
                                                position: 'relative',
                                                cursor: 'pointer',
                                                '&:hover .media-overlay': {
                                                    opacity: 1,
                                                },
                                            }}
                                        >
                                            <CardMedia
                                                component="img"
                                                sx={{ 
                                                    height: { xs: 200, sm: 180 },
                                                    objectFit: 'contain',
                                                    borderRadius: 1,
                                                    bgcolor: 'background.paper',
                                                }}
                                                image={card?.thumbnail || RajOffsetProcessingImage}
                                                alt={card?.title || 'Processing'}
                                            />
                                            <Box
                                                className="media-overlay"
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    bgcolor: 'rgba(0, 0, 0, 0.4)',
                                                    opacity: 0,
                                                    transition: 'opacity 0.2s ease-in-out',
                                                    borderRadius: 1,
                                                }}
                                            >
                                                <FullscreenIcon sx={{ color: 'white', fontSize: 32 }} />
                                            </Box>
                                        </Box>

                                        {/* Content Section */}
                                        <Box 
                                            sx={{ 
                                                flex: 1,
                                                order: { xs: 2, sm: 1 },
                                            }}
                                        >
                                            <Box sx={{ 
                                                display: 'flex', 
                                                justifyContent: 'space-between', 
                                                alignItems: 'center',
                                                mb: 1,
                                            }}>
                                                <Typography 
                                                    variant="body2" 
                                                    color="textSecondary"
                                                    sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
                                                >
                                                        #{card?.id?.slice(0, 8)}
                                                    </Typography>
                                                <IconButton 
                                                    onClick={(event) => handleMenuClick(event, card.id)}
                                                    sx={{ p: 0.5 }}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </Box>

                                            <Typography 
                                                variant="h6" 
                                                sx={{ 
                                                    fontSize: { xs: '1.25rem', sm: '1.5rem' },
                                                    fontWeight: 600,
                                                    mb: 1,
                                                    color: theme.palette.mode === 'dark' 
                                                        ? '#c5cae9' 
                                                        : '#303f9f',
                                                    textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
                                                }}
                                            >
                                                        {card?.title}
                                                    </Typography>

                                            <Typography 
                                                variant="body2" 
                                                sx={{ 
                                                    color: theme.palette.text.secondary,
                                                    fontSize: { xs: '0.875rem', sm: '1rem' },
                                                    mb: 2,
                                                    lineHeight: 1.5,
                                                }}
                                            >
                                                        {card?.entry_associated_card?.card_description}
                                                    </Typography>

                                            <Box 
                                                sx={{ 
                                                    mb: 2,
                                                    width: '100%',
                                                    background: theme.palette.mode === 'dark'
                                                        ? 'linear-gradient(45deg, rgba(63, 81, 181, 0.1), rgba(40, 53, 147, 0.1))'
                                                        : 'linear-gradient(45deg, rgba(63, 81, 181, 0.1), rgba(26, 35, 126, 0.1))',
                                                    borderRadius: 2,
                                                    p: 2,
                                                    border: `1px solid ${theme.palette.mode === 'dark'
                                                        ? 'rgba(63, 81, 181, 0.2)'
                                                        : 'rgba(63, 81, 181, 0.2)'}`,
                                                }}
                                            >
                                                        <Typography
                                                            sx={{
                                                        color: theme.palette.mode === 'dark' 
                                                            ? '#c5cae9' 
                                                            : '#303f9f',
                                                        fontSize: { xs: '0.875rem', sm: '1rem' },
                                                        fontWeight: 500,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {card?.status === 'ready' 
                                                        ? '✨ Your Wedding E-invite is Ready to Share ✨'
                                                        : '🎨 Creating Your Beautiful Invitation... (30 minutes remaining)'
                                                    }
                                                        </Typography>
                                            </Box>

                                            {/* Action Buttons with enhanced styling */}
                                            <Stack 
                                                direction={{ xs: 'column', sm: 'row' }}
                                                spacing={{ xs: 1.5, sm: 2 }}
                                                sx={{ mt: 2 }}
                                            >
                                                {card?.edit_counter > 0 && (
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => navigate(`/edit-digital-card/${card?.id}`)}
                                                        fullWidth={isMobile}
                                                        sx={{
                                                            py: { xs: 1.5, sm: 1.5 },
                                                            borderRadius: 2,
                                                            textTransform: 'none',
                                                            fontSize: { xs: '0.875rem', sm: '1rem' },
                                                            borderColor: theme.palette.mode === 'dark' 
                                                                ? '#3f51b5' 
                                                                : '#3f51b5',
                                                            color: theme.palette.mode === 'dark' 
                                                                ? '#3f51b5' 
                                                                : '#3f51b5',
                                                            '&:hover': {
                                                                borderColor: theme.palette.mode === 'dark' 
                                                                    ? '#5c6bc0' 
                                                                    : '#5c6bc0',
                                                                backgroundColor: theme.palette.mode === 'dark'
                                                                    ? 'rgba(63, 81, 181, 0.1)'
                                                                    : 'rgba(63, 81, 181, 0.1)',
                                                            },
                                                        }}
                                                    >
                                                        Edit Card
                                                    </Button>
                                                )}

                                                    <Button
                                                    variant="contained"
                                                    onClick={() => handleSendInvitation(card?.id)}
                                                    fullWidth={isMobile}
                                                    sx={{
                                                        py: { xs: 1.5, sm: 1.5 },
                                                        borderRadius: 2,
                                                        textTransform: 'none',
                                                        fontSize: { xs: '0.875rem', sm: '1rem' },
                                                        background: theme.palette.mode === 'dark'
                                                            ? 'linear-gradient(45deg, #3f51b5 30%, #1a237e 90%), linear-gradient(135deg, rgba(103, 58, 183, 0.4), transparent)'
                                                            : 'linear-gradient(45deg, #3f51b5 30%, #1a237e 90%), linear-gradient(135deg, rgba(103, 58, 183, 0.4), transparent)',
                                                        backgroundBlendMode: 'soft-light',
                                                        boxShadow: theme.palette.mode === 'dark'
                                                            ? '0 3px 10px rgba(63, 81, 181, 0.3), 0 5px 15px rgba(40, 53, 147, 0.2)'
                                                            : '0 3px 10px rgba(63, 81, 181, 0.3), 0 5px 15px rgba(26, 35, 126, 0.2)',
                                                        '&:hover': {
                                                            background: theme.palette.mode === 'dark'
                                                                ? 'linear-gradient(45deg, #5c6bc0 30%, #3f51b5 90%), linear-gradient(135deg, rgba(103, 58, 183, 0.5), transparent)'
                                                                : 'linear-gradient(45deg, #3f51b5 30%, #283593 90%), linear-gradient(135deg, rgba(103, 58, 183, 0.5), transparent)',
                                                        },
                                                    }}
                                                >
                                                    Send Invitation
                                                </Button>

                                                <Button
                                                    variant="outlined"
                                                    onClick={() => handleAddMemberOpen(card?.id)}
                                                    fullWidth={isMobile}
                                                    sx={{
                                                        py: { xs: 1.5, sm: 1.5 },
                                                        borderRadius: 2,
                                                        textTransform: 'none',
                                                        fontSize: { xs: '0.875rem', sm: '1rem' },
                                                    }}
                                                >
                                                    Add Member
                                                </Button>

                                                {card?.final_url && (
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => {
                                                            const link = document.createElement('a');
                                                            link.href = card.final_url;
                                                            link.target = '_blank';
                                                            link.download = 'invitation.pdf';
                                                            document.body.appendChild(link);
                                                            link.click();
                                                            document.body.removeChild(link);
                                                        }}
                                                        fullWidth={isMobile}
                                                        sx={{
                                                            py: { xs: 1.5, sm: 1.5 },
                                                            borderRadius: 2,
                                                            textTransform: 'none',
                                                            fontSize: { xs: '0.875rem', sm: '1rem' },
                                                        }}
                                                    >
                                                        Download
                                                    </Button>
                                                )}
                                            </Stack>
                                        </Box>
                                        </Box>

                                    {/* Timestamps */}
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            display: 'block',
                                            textAlign: 'right',
                                            mt: 2,
                                            color: theme.palette.text.secondary,
                                            fontSize: { xs: '0.7rem', sm: '0.75rem' },
                                        }}
                                    >
                                        Created: {new Date(card?.createdAt).toLocaleDateString()} • 
                                        Updated: {new Date(card?.updatedAt).toLocaleDateString()}
                                    </Typography>
                                </CardContent>
                                </Card>
                        ))
                    ) : (
                        <Box 
                            sx={{ 
                                p: { xs: 4, sm: 5 },
                                textAlign: 'center',
                                background: theme.palette.mode === 'dark'
                                    ? 'linear-gradient(135deg, rgba(40, 53, 147, 0.15) 0%, rgba(26, 35, 126, 0.25) 100%), linear-gradient(45deg, rgba(103, 58, 183, 0.1), rgba(63, 81, 181, 0.1))'
                                    : 'linear-gradient(135deg, rgba(197, 202, 233, 0.3) 0%, rgba(179, 157, 219, 0.3) 100%), linear-gradient(45deg, rgba(63, 81, 181, 0.1), rgba(103, 58, 183, 0.1))',
                                backgroundBlendMode: 'overlay',
                                borderRadius: { xs: 0, sm: 2 },
                                border: `1px solid ${theme.palette.mode === 'dark' 
                                    ? 'rgba(255,255,255,0.1)' 
                                    : 'rgba(63, 81, 181, 0.2)'}`,
                            }}
                        >
                            <Typography 
                                sx={{ 
                                    color: theme.palette.mode === 'dark' 
                                        ? '#c5cae9' 
                                        : '#303f9f',
                                    fontSize: { xs: '1rem', sm: '1.25rem' },
                                    fontWeight: 500,
                                    textShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                }}
                            >
                                ✨ Create your first magical wedding invitation! ✨
                            </Typography>
                        </Box>
                    )}
                </Stack>
            )}

            {/* Send Invitation Dialog */}
            <Dialog
                open={openInvitation}
                onClose={handleSendInvitationClose}
                fullScreen={isMobile}
                PaperProps={{
                    sx: {
                        width: { sm: '500px' },
                        maxWidth: '100%',
                        m: { xs: 0, sm: 2 },
                        borderRadius: { xs: 0, sm: 2 },
                    },
                }}
            >
                <DialogTitle 
                    sx={{ 
                        p: { xs: 2, sm: 3 },
                        fontSize: { xs: '1.25rem', sm: '1.5rem' },
                        fontWeight: 600,
                    }}
                >
                    Send Digital Invitation
                </DialogTitle>
                
                <DialogContent sx={{ p: { xs: 2, sm: 3 } }}>
                    <Typography 
                        variant="body2" 
                        sx={{ 
                            mb: 3,
                            color: theme.palette.text.secondary,
                            fontSize: { xs: '0.875rem', sm: '1rem' },
                            lineHeight: 1.5,
                        }}
                    >
                        Attendee will receive E-invite on WhatsApp, SMS and a reminder through phone call. 
                        Please enter the details carefully as they will appear on the invitation.
                    </Typography>

                    <Stack component="form" onSubmit={handleSendDigitalInvitation} spacing={2}>
                        <TextField
                            label="Attendee Name"
                            value={attendeeName}
                            onChange={(e) => handleTextChange(e, setAttendeeName)}
                            required
                            fullWidth
                            size={isMobile ? "small" : "medium"}
                        />

                        <TextField
                            label="WhatsApp Number"
                            value={attendeeMobile}
                            onChange={(e) => setAttendeeMobile(e.target.value)}
                            required
                            fullWidth
                            size={isMobile ? "small" : "medium"}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Select
                                            value={selectedCountry.dial_code}
                                            onChange={handleCountryChange}
                                            size={isMobile ? "small" : "medium"}
                                            sx={{
                                                '& .MuiSelect-select': {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    py: 0.5,
                                                },
                                            }}
                                        >
                                            {countries.map((country) => (
                                                <MenuItem key={country.dial_code} value={country.dial_code}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <img
                                                        src={country.flag}
                                                        alt={country.name}
                                                            style={{ width: 20, height: 15 }}
                                                    />
                                                        <Typography variant="body2">
                                                            {country.dial_code}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <TextField
                            label="Email (Optional)"
                            value={attendeeEmail}
                            onChange={(e) => setAttendeeEmail(e.target.value)}
                            onBlur={validateEmail}
                            fullWidth
                            size={isMobile ? "small" : "medium"}
                            error={emailError}
                            helperText={emailError ? "Please enter a valid email address" : ""}
                        />

                        <TextField
                            label="Message"
                            value={attendeeMessage}
                            onChange={(e) => handleTextChange(e, setAttendeeMessage)}
                            required
                            fullWidth
                            size={isMobile ? "small" : "medium"}
                            multiline
                            rows={2}
                        />
                    </Stack>
                </DialogContent>

                <Divider />

                <DialogActions sx={{ p: { xs: 2, sm: 3 }, gap: 1 }}>
                    <Button 
                        onClick={handleSendInvitationClose}
                        variant="outlined"
                        fullWidth={isMobile}
                        sx={{ 
                            py: { xs: 1, sm: 1.5 },
                            textTransform: 'none',
                        }}
                    >
                        Cancel
                    </Button>
                    <Button 
                        type="submit"
                        variant="contained"
                        fullWidth={isMobile}
                        disabled={attendeeMobile.length !== selectedPhoneLength}
                        sx={{ 
                            py: { xs: 1, sm: 1.5 },
                            textTransform: 'none',
                        }}
                    >
                        Send Invitation
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Dialog */}
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                PaperProps={{
                    sx: {
                        width: { xs: '90%', sm: '400px' },
                        borderRadius: { xs: 2, sm: 2 },
                        p: { xs: 2, sm: 3 },
                    },
                }}
            >
                <DialogTitle sx={{ p: 0, mb: 2, fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>
                    Delete Digital Card
                </DialogTitle>
                <DialogContent sx={{ p: 0, mb: 2 }}>
                    <DialogContentText sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                        Are you sure you want to delete this card? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ p: 0, gap: 1 }}>
                    <Button 
                        onClick={handleCloseDelete}
                        variant="outlined"
                        sx={{ textTransform: 'none' }}
                    >
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleDigitalCardDelete}
                        variant="contained"
                        color="error"
                        sx={{ textTransform: 'none' }}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <AddMember
                open={openAddMember}
                handleClose={closeHandleAddMember}
                card={digitalCardId}
            />

            {/* Add MediaViewer component */}
            <MediaViewer
                open={Boolean(selectedMedia)}
                onClose={handleMediaClose}
                media={selectedMedia}
                type={mediaType}
            />
        </Container>
    )
}

export default DigitalCards