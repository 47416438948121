import React from 'react';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles'; // Updated import
import 'animate.css';
import CardSidebar from './CardSidebar';
import { Outlet } from 'react-router-dom';


const CardLayout = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('sm'));



    return (
        <Box sx={{ overflowX: 'hidden', padding: '16px', marginX: 'auto', maxWidth: '100%', marginTop: 5 }}>
            {isMatch ? (
                <Grid container spacing={2} sx={{ marginX: 'auto' }}>
                    {/* Main Content */}
                    <Outlet />
                    {/*Sidebar*/}
                    <CardSidebar />
                </Grid>
            ) : (
                <Grid container spacing={2} sx={{ marginX: 'auto' }}>
                    {/* Sidebar */}
                    <CardSidebar />
                    {/* Main Content */}
                    <Outlet />
                </Grid>
            )}
        </Box>
    );
};

export default CardLayout;
