import { useTheme } from '@emotion/react';
import { Box, Button, CircularProgress, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSingleProfileData, updateProfileDetailsData } from '../../redux/actions/multiProfileActions';
import countries from "../../components/countries.json"

function EditProfileDetails() {
    const params = useParams();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [loading, setLoading] = useState(false);
    const [fetchingData, setFetchingData] = useState(true); // New state for fetching data

    const singleProfile = useSelector((state) => state?.multiProfile?.profileData?.data);

    useEffect(() => {
        const fetchSingleProfile = async () => {
            setFetchingData(true);
            await dispatch(getSingleProfileData(params.profileId));
            setFetchingData(false);
        };
        fetchSingleProfile();
    }, [dispatch, params.profileId]);

    useEffect(() => {
        if (singleProfile) {
            setEditName(singleProfile?.name);
            setEditAbout(singleProfile?.about);
            setEditEmail(singleProfile?.email);
            setEditMobile(singleProfile?.mobile1);
            setEditAddress(singleProfile?.address);
            setEditDistrict(singleProfile?.district);
            setEditState(singleProfile?.state);
            setEditCountry(singleProfile?.country);
            setEditPincode(singleProfile?.pincode);
            setEditTaxId(singleProfile?.taxId);
            setEditOwner(singleProfile?.owner);
        }
    }, [singleProfile]);

    const [editName, setEditName] = useState('');
    const [editAbout, setEditAbout] = useState('');
    const [editEmail, setEditEmail] = useState('');
    const [editMobile, setEditMobile] = useState('');
    const [editAddress, setEditAddress] = useState('');
    const [editDistrict, setEditDistrict] = useState('');
    const [editState, setEditState] = useState('');
    const [editCountry, setEditCountry] = useState('');
    const [editPincode, setEditPincode] = useState('');
    const [editTaxId, setEditTaxId] = useState('');
    const [editOwner, setEditOwner] = useState('');

    const [errors, setErrors] = useState({});

    // Validation logic
    const validateField = (field, value) => {
        let errorMsg = '';

        switch (field) {
            case 'mobile':
                if (!/^\d{10}$/.test(value)) errorMsg = 'Mobile number must be 10 digits';
                break;
            case 'pincode':
                if (!/^\d+$/.test(value)) errorMsg = 'Pincode must contain only digits';
                break;
            case 'email':
                if (!/\S+@\S+\.\S+/.test(value)) errorMsg = 'Invalid email address';
                break;
            default:
                if (!value) errorMsg = 'This field is required';
                break;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: errorMsg,
        }));
    };

    // Handlers for input changes with validation
    const handleEditMobileChange = (e) => {
        const value = e.target.value;
        setEditMobile(value);
        validateField('mobile', value);
    };

    const handleEditPincodeChange = (e) => {
        const value = e.target.value;
        setEditPincode(value);
        validateField('pincode', value);
    };

    const handleEditEmailChange = (e) => {
        const value = e.target.value;
        setEditEmail(value);
        validateField('email', value);
    };

    // Check if form is valid
    const isFormValid = () => {
        return Object.values(errors).every((err) => !err) && editName && editAbout && editEmail && editMobile;
    };

    const editProfileDetail = async () => {
        const editData = {
            profileId: params.profileId,
            name: editName,
            mobile1: editMobile,
            email: editEmail,
            address: editAddress,
            district: editDistrict,
            state: editState,
            country: editCountry,
            owner: editOwner,
            about: editAbout,
        };
        setLoading(true);
        await dispatch(updateProfileDetailsData(editData));
        setLoading(false);
    };

    // Show loading spinner while data is being fetched
    if (fetchingData) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                Please wait, while fetching data
            </Box>
        );
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    md={7}
                    sx={{
                        padding: 2,
                        borderRadius: '8px',
                        backgroundColor: '#E2EAF4',
                        marginLeft: { md: 10, xs: 0 },
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#eceff1',
                            padding: 2,
                            color: theme.palette.text.primary,
                        }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Typography>Edit Profile for Edit Id #{params.profileId}</Typography>

                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                <TextField
                                    label="Name"
                                    value={editName}
                                    onChange={(e) => setEditName(e.target.value)}
                                    error={!!errors.editName}
                                    helperText={errors.editName}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    label="About"
                                    value={editAbout}
                                    onChange={(e) => setEditAbout(e.target.value)}
                                    error={!!errors.editAbout}
                                    helperText={errors.editAbout}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>

                            {params.profile !== 'personal' && (
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                    <TextField
                                        label="Address"
                                        value={editAddress}
                                        onChange={(e) => setEditAddress(e.target.value)}
                                        error={!!errors.editAddress}
                                        helperText={errors.editAddress}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <TextField
                                        label="District"
                                        value={editDistrict}
                                        onChange={(e) => setEditDistrict(e.target.value)}
                                        error={!!errors.editDistrict}
                                        helperText={errors.editDistrict}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                            )}

                            {params.profile !== 'personal' && (
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                    <TextField
                                        label="State"
                                        value={editState}
                                        onChange={(e) => setEditState(e.target.value)}
                                        error={!!errors.editState}
                                        helperText={errors.editState}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <TextField
                                        label="Country"
                                        value={editCountry}
                                        onChange={(e) => setEditCountry(e.target.value)}
                                        error={!!errors.editCountry}
                                        helperText={errors.editCountry}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <TextField
                                        label="Pincode"
                                        value={editPincode}
                                        onChange={handleEditPincodeChange}
                                        error={!!errors.editPincode}
                                        helperText={errors.editPincode}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                            )}

                            {(params.profile === 'company' || params.profile === 'small_business' || params.profile === 'organization') && (
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                    <TextField
                                        label="TaxId"
                                        value={editTaxId}
                                        error={!!errors.editTaxId}
                                        helperText={errors.editTaxId}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <TextField
                                        label="Owner"
                                        value={editOwner}
                                        onChange={(e) => setEditOwner(e.target.value)}
                                        error={!!errors.editOwner}
                                        helperText={errors.editOwner}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                            )}

                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                <TextField
                                    label="Mobile"
                                    value={editMobile}
                                    onChange={handleEditMobileChange}
                                    error={!!errors.editMobile}
                                    helperText={errors.editMobile}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <img
                                              src="https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg"
                                              alt="Indian Flag"
                                              style={{ width: '24px', height: '16px', marginRight: '8px' }}
                                            />
                                            <span>+91</span>
                                          </InputAdornment>
                                        ),
                                      }}
                                />
                                <TextField
                                    label="Email"
                                    value={editEmail}
                                    onChange={handleEditEmailChange}
                                    error={!!errors.editEmail}
                                    helperText={errors.editEmail}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                        </Box>

                        <Button
                            variant="contained"
                            disabled={!isFormValid() || loading}
                            onClick={editProfileDetail}
                            sx={{ marginTop: 2 }}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Save Profile'}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}

export default EditProfileDetails;
