import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import logo from '../assets/icons/Logo.png'

const Loading = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start', 
        alignItems: 'center', 
        height: '100vh',
        paddingTop: '5vh', 
        backgroundColor: 'background.default',
      }}
    >
      
      <img
        src={logo}
        alt="Logo"
        style={{
          width: 300, 
          height: 100,
          marginBottom: 16,
        }}
      />
      <Typography variant="h6" gutterBottom>
        
      </Typography>
      <LinearProgress
        sx={{
          width: '40%', 
        }}
      />
    </Box>
  );
};

export default Loading;
