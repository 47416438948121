import React, { useState } from 'react';
import { Drawer, IconButton, List, ListItemButton, ListItemText, Box, MenuItem, Button, LinearProgress, CircularProgress, Dialog, DialogContent, Typography, } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import StyledMenu from './StyledMenu';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme as useAppTheme } from '../theme/ThemeContext';
import { Logout } from '../redux/actions/authActions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';




const DrawerComp = ({ openMenu, handleMenuClick, handleClose, menuStates, buttonLabels, menuItems }) => {
    const [open, setOpen] = useState(false);

    console.log("menu item in drawer", menuItems);

    const handleDrawerToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleMenuClose = () => {
        handleClose();
        // Ensure drawer remains open when menu is closed
    };
    // const handleDrawerClose = () => {
    //     setOpen(false);
    // };

    const handleLogin = () => {
        setOpen(false);
        navigate('/login');
    };

    const navigate = useNavigate();

    const handleNavigation = (path_url) => {
        navigate(`/cards/${path_url}`);
        setOpen(false);
    };
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const { isDarkMode, } = useAppTheme();
    const [avatarMenuAnchorEl, setAvatarMenuAnchorEl] = useState(null);
    const dispatch = useDispatch();


    const handleAvatarMenuClose = () => {
        setAvatarMenuAnchorEl(null);
    };

    const [logoutOpen, setLogoutOpen] = useState(false);

    const handleLogout = async () => {
        setLogoutOpen(true);
        toast.info("Please wait, logging out...");

        try {
            setTimeout(async () => {
                await dispatch(Logout());
                setLogoutOpen(false);
            }, 10000);
        } catch (error) {
            console.error("Logout failed:", error);
            setLogoutOpen(false);
        }
    };




    return (
        <>
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <List sx={{ width: 250 }}>
                    {buttonLabels.map((label, index) => (
                        <ListItemButton key={index} onClick={(e) => handleMenuClick(e, index + 1)}>
                            <ListItemText primary={label} />
                            <KeyboardArrowDownIcon />
                            <StyledMenu
                                id={`drawer-customized-menu-${index + 1}`}
                                anchorEl={menuStates[index].anchorEl}
                                open={openMenu && menuStates[index].isOpen}
                                onClose={handleMenuClose}
                            >

                                {menuItems[index + 1].map((item, itemIndex) => (
                                    <MenuItem key={itemIndex} onClick={() => handleNavigation(item.path)} disableRipple>
                                        {item.label}
                                    </MenuItem>
                                ))}

                            </StyledMenu>
                        </ListItemButton>
                    ))}
                </List>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    {!isAuthenticated ? (
                        <Button
                            sx={{
                                textTransform: 'none',
                                color: isDarkMode ? '#BB86FC' : 'black'
                            }}
                            onClick={handleLogin}
                        >
                            Login/Register
                        </Button>
                    ) : (
                        <Button
                            sx={{
                                textTransform: 'none',
                                color: isDarkMode ? '#BB86FC' : 'black'
                            }}
                            onClick={handleLogout}
                        >
                            Logout
                        </Button>
                    )}

                    <Button
                        variant="contained"
                        fullWidth
                        sx={{
                            textTransform: 'none',
                            color: 'white',
                            mt: 2
                        }}
                    >
                        Download App
                    </Button>
                </Box>

            </Drawer>

            <IconButton sx={{ marginLeft: 'auto' }} onClick={handleDrawerToggle}>
                <MenuRoundedIcon />
            </IconButton>
            <Dialog
                open={logoutOpen}
                PaperProps={{
                    style: {
                        borderRadius: "12px",
                        padding: "20px",
                        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
                    },
                }}
                BackdropProps={{
                    style: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        backdropFilter: "blur(4px)",
                    },
                }}
            >
                <DialogContent
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        gap: "20px",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#e3f2fd",
                            borderRadius: "50%",
                            width: "50px",
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress size={30} color="primary" />
                    </div>

                    <Typography variant="h6" style={{ fontWeight: "bold", color: "#424242" }}>
                        Logging out, please wait...
                    </Typography>

                    <LinearProgress
                        style={{
                            width: "100%",
                            height: "8px",
                            borderRadius: "4px",
                            backgroundColor: "#e0e0e0",
                        }}
                        color="primary"
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DrawerComp;
