import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { AppBar, Box, Toolbar, Typography, useMediaQuery, IconButton, MenuItem, Menu, Dialog, DialogContent, CircularProgress, LinearProgress } from '@mui/material';
import Logo from '../assets/icons/Logo.png';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DrawerComp from './DrawerComp';
import { useTheme } from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';  // Dark mode icon
import Brightness7Icon from '@mui/icons-material/Brightness7';  // Light mode icon
import { useTheme as useAppTheme } from '../theme/ThemeContext';
import StyledMenu from './StyledMenu';
import { useSelector, useDispatch } from 'react-redux';
import { Logout } from '../redux/actions/authActions'; // Import your logout action
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import { toast } from 'react-toastify';


function Navbar() {
    const [selectedButton, setSelectedButton] = useState(null);
    const [anchorEl, setAnchorEl] = useState([null, null, null, null]);
    const [avatarMenuAnchorEl, setAvatarMenuAnchorEl] = useState(null);
    const buttonLabels = ['Wedding', 'Baby & Kids', 'Occasions', 'Pooja'];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const menuItems = {
        1: [
            { label: 'Wedding Invitation', path: 'wedding' },
            { label: 'Engagement Invitation', path: 'engagement' },
            { label: 'Muslim Wedding Cards', path: 'muslim-wedding' },
            { label: 'Haldi Invitation', path: 'haldi' },
            { label: 'Sangeet Invitation', path: 'sangeet' },
            { label: 'Mehndi Ceremony', path: 'mehndi' },
            { label: 'Anniversary Invitation', path: 'anniversary' },
            { label: 'Tilak Ceremony', path: 'tilak' },
        ],
        2: [
            { label: 'Birthday Invitation Card', path: 'birthday-invitation-card' },
            { label: '1st Birthday Invitation Card', path: '1st-birthday-invitation-card' },
            { label: 'Mundan Card', path: 'mundan' },
            { label: 'Annaprashan Card', path: 'annaprashan-card' },
            { label: 'Thread Ceremony Invitation', path: 'thread-ceremony' },
            { label: 'Naming Ceremony Invitation', path: 'naming-ceremony' },
        ],
        3: [
            { label: 'House Warming Invitation', path: 'housewarming' },
            { label: 'Shop Opening Invitation', path: 'shop-opening' },
            { label: 'Vastu Shanti Invitation Card', path: 'vastu-shanti-invitation-card' },
            { label: 'Ganpati Invitation Card', path: 'ganpati-invitation-card' },
            { label: 'Kitty Party Invitation', path: 'kitty-party' },
            { label: 'Shok Sandesh Card', path: 'shok-sandesh-card' },
        ],
        4: [
            { label: 'Maa Ki Chowki', path: 'maa-ki-chowki' },
            { label: 'Satyanarayana Pooja Invite', path: 'satyanarayana-pooja-invite' },
            { label: 'Bhandara', path: 'bhandara' },
        ],
    };



    const handleMenuClick = (event, buttonIndex) => {
        setAnchorEl(prevAnchorEl => {
            const newAnchorEl = [...prevAnchorEl];
            newAnchorEl[buttonIndex - 1] = event.currentTarget;
            return newAnchorEl;
        });
        setSelectedButton(buttonIndex);
    };

    const handleClose = () => {
        setAnchorEl([null, null, null, null]);
        setSelectedButton(null);
    };

    const handleAvatarMenuClick = (event) => {
        setAvatarMenuAnchorEl(event.currentTarget);
    };

    const handleAvatarMenuClose = () => {
        setAvatarMenuAnchorEl(null);
    };



    const [logoutOpen, setLogoutOpen] = useState(false);

    const handleLogout = async () => {
        setLogoutOpen(true);
        toast.info("Please wait, logging out...");

        try {
            setTimeout(async () => {
                await dispatch(Logout());
                setLogoutOpen(false);
            }, 10000);
        } catch (error) {
            console.error("Logout failed:", error);
            setLogoutOpen(false);
        }
    };
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    useEffect(() => {
        if (isAuthenticated) {
            console.log("step 1");
            const autoLogoutInterval = setInterval(() => {
                console.log("step 2");
                handleLogout();
            }, 60000 * 600);
            return () => clearInterval(autoLogoutInterval);
        }
    }, [isAuthenticated]); // Dependency on isAuthenticated

    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('sm'));
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));

    const menuStates = [1, 2, 3, 4].map((i) => ({
        isOpen: selectedButton === i,
        anchorEl: anchorEl[i - 1],
    }));

    const { isDarkMode, toggleTheme } = useAppTheme();

    const handleNavigation = (path_url) => {
        navigate(`/cards/${path_url}`);
        handleClose();
    };


    // Get authentication status

    return (
        <>
            <AppBar position='static' elevation={0} sx={{ backgroundColor: isDarkMode ? '#121212' : 'white' }}>
                <Toolbar>
                    {isMatch ? (
                        <>
                            <Typography
                                variant="h6"
                                noWrap component="div"
                                sx={{ paddingLeft: 1, color: isDarkMode ? 'white' : '#003366', fontWeight: 'bold' }}
                                onClick={() => navigate(`/`)}
                            >
                                <img src={Logo} height={'50px'} alt="Logo" />
                            </Typography>
                            <DrawerComp
                                openMenu={Boolean(anchorEl[selectedButton - 1])}
                                handleMenuClick={handleMenuClick}
                                handleClose={handleClose}
                                menuStates={menuStates}
                                buttonLabels={buttonLabels}
                                menuItems={menuItems}
                            />
                        </>
                    ) : isMedium ? (
                        <>
                            <Box sx={{ display: 'flex', gap: 30 }}>
                                <Typography
                                    variant="h6"
                                    noWrap component="div"
                                    sx={{ paddingLeft: 1, color: isDarkMode ? 'white' : '#003366', fontWeight: 'bold' }}
                                    onClick={() => navigate(`/`)}
                                >
                                    <img src={Logo} height={'50px'} alt="Logo" />
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {!isAuthenticated ? (
                                        <>
                                            <Button sx={{ textTransform: 'none', color: isDarkMode ? '#BB86FC' : 'black', marginLeft: 'auto' }} onClick={() => navigate('/login')}>Login</Button>
                                            <Button sx={{ textTransform: 'none', color: isDarkMode ? '#BB86FC' : 'black', marginLeft: 1 }} onClick={() => navigate('/register')}>SignUp</Button>
                                        </>
                                    ) : (
                                        <>
                                            <Avatar
                                                // Optional: Show user avatar if available
                                                onClick={handleAvatarMenuClick}
                                                sx={{ cursor: 'pointer' }}
                                            />
                                            <Menu
                                                anchorEl={avatarMenuAnchorEl}
                                                open={Boolean(avatarMenuAnchorEl)}
                                                onClose={handleAvatarMenuClose}
                                            >
                                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                            </Menu>
                                        </>
                                    )}
                                    <Button variant='contained' sx={{
                                        textTransform: 'none',
                                        color: 'white',
                                        backgroundColor: isDarkMode ? '#BB86FC' : '#b71c1c',
                                        width: { xs: '100%', sm: 'auto' },
                                        maxWidth: 200,
                                        minWidth: 120,
                                        fontSize: '0.725rem',
                                        height: 40
                                    }}>
                                        Download App
                                    </Button>
                                </Box>
                                <IconButton onClick={toggleTheme} sx={{ marginLeft: 2 }}>
                                    {isDarkMode ? <Brightness7Icon sx={{ color: 'white' }} /> : <Brightness4Icon sx={{ color: 'black' }} />}
                                </IconButton>
                            </Box>
                        </>
                    ) : (
                        <Grid container sx={{ placeItems: 'center' }}>
                            <Grid item xs={2}>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="div"
                                    sx={{ paddingLeft: 1, color: isDarkMode ? 'white' : '#003366', fontWeight: 'bold' }}
                                    onClick={() => navigate(`/`)}
                                >
                                    <img src={Logo} height={'50px'} alt="Logo" />
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {buttonLabels.map((label, index) => (
                                        <React.Fragment key={index}>
                                            <Button
                                                id={`demo-customized-button-${index + 1}`}
                                                aria-controls={Boolean(anchorEl[index]) ? `demo-customized-menu-${index + 1}` : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={Boolean(anchorEl[index]) && selectedButton === index + 1 ? 'true' : undefined}
                                                disableElevation
                                                onClick={(e) => handleMenuClick(e, index + 1)}
                                                endIcon={<KeyboardArrowDownIcon />}
                                                sx={{
                                                    textTransform: 'none',
                                                    backgroundColor: selectedButton === index + 1 ? 'lightgray' : 'transparent',
                                                    color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black'
                                                }}
                                            >
                                                {label}
                                            </Button>
                                            <StyledMenu
                                                id={`demo-customized-menu-${index + 1}`}
                                                anchorEl={anchorEl[index]}
                                                open={Boolean(anchorEl[index]) && selectedButton === index + 1}
                                                onClose={handleClose}
                                            >
                                                {menuItems[index + 1].map((item, itemIndex) => (
                                                    <MenuItem key={itemIndex} onClick={() => handleNavigation(item.path)} disableRipple>
                                                        {item.label}
                                                    </MenuItem>
                                                ))}


                                            </StyledMenu>
                                        </React.Fragment>
                                    ))}
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {/* <Button
                                        variant='contained'
                                        sx={{
                                            textTransform: 'none',
                                            color: 'white',
                                            backgroundColor: isDarkMode ? '#BB86FC' : '#b71c1c',
                                            width: { xs: '100%', sm: 'auto' },
                                            maxWidth: 200,
                                            minWidth: 120,
                                            fontSize: '0.725rem',
                                            height: 40,
                                            marginLeft: 1
                                        }}
                                    >
                                        Download App
                                    </Button> */}
                                    <IconButton onClick={toggleTheme} sx={{ marginLeft: 2 }}>
                                        {isDarkMode ? <Brightness7Icon sx={{ color: 'white' }} /> : <Brightness4Icon sx={{ color: 'black' }} />}
                                    </IconButton>

                                    {!isAuthenticated ? (
                                        <>
                                            <Button sx={{ textTransfor7m: 'none', color: isDarkMode ? '#BB86FC' : 'black' }} onClick={() => navigate('/login')}>Login/Register</Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button sx={{ textTransfor7m: 'none', color: isDarkMode ? '#BB86FC' : 'black' }} onClick={() => navigate('/dashboard')}>Dashboard</Button>
                                            <Avatar
                                                onClick={handleAvatarMenuClick}
                                                sx={{ cursor: 'pointer' }}
                                            />
                                            <Menu
                                                anchorEl={avatarMenuAnchorEl}
                                                open={Boolean(avatarMenuAnchorEl)}
                                                onClose={handleAvatarMenuClose}
                                            >
                                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                            </Menu>

                                        </>
                                    )}
                                </Box>
                            </Grid>


                        </Grid>
                    )}
                </Toolbar>
            </AppBar>
            <Typography
                variant="body1"
                sx={{
                    backgroundColor: isDarkMode ? '#1E1E1E' : '#b71c1c',
                    color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'white',
                    textAlign: 'center',
                    fontSize: { xs: '0.725rem', sm: '1rem', md: '1rem' },
                }}
            >
                Special Offer Ending Soon. Get Newly Launched Digital Invitation Card (PDF) starting from ₹ 11 (sagun). Offer valid till {moment().add(1, 'days').format('MMMM Do YYYY')}
            </Typography>
            <Dialog
                open={logoutOpen}
                PaperProps={{
                    style: {
                        borderRadius: "12px",
                        padding: "20px",
                        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
                    },
                }}
                BackdropProps={{
                    style: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        backdropFilter: "blur(4px)",
                    },
                }}
            >
                <DialogContent
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        gap: "20px",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#e3f2fd",
                            borderRadius: "50%",
                            width: "50px",
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress size={30} color="primary" />
                    </div>

                    <Typography variant="h6" style={{ fontWeight: "bold", color: "#424242" }}>
                        Logging out, please wait...
                    </Typography>

                    <LinearProgress
                        style={{
                            width: "100%",
                            height: "8px",
                            borderRadius: "4px",
                            backgroundColor: "#e0e0e0",
                        }}
                        color="primary"
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}

export default Navbar;
