import React, { useEffect } from 'react';
import { Grid, Box, Typography, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import packageJson from '../../package.json';



function Footer() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const appVersion = packageJson.version;


  const navigate = useNavigate();
  const menuItems = [
    { label: 'FAQ', path: '/faq' },
    { label: 'About Us', path: '/about-us' },
    { label: 'Contact Us', path: '/contact-us' },
    { label: 'Terms & Conditions', path: '/terms-and-conditions' },
    { label: 'Privacy Policy', path: '/privacy-policy' },
    { label: 'Refund Policy', path: '/refund-policy' },
    { label: 'Delete Account Policy', path: '/delete-account-policy' },
    { label: 'Support Ticket', path: '/support-ticket' },
  ];

  const invitationCategory = [
    { label: 'Engagement Invitation', path: 'engagement' },
    { label: 'Wedding Invitation', path: 'wedding' },
    { label: 'House Warming Invitation', path: 'housewarming' },
    { label: 'Shop Grand Opening', path: 'shop-opening' },
    { label: 'Mundan Invitation', path: 'mundan' },
    { label: 'Naming Ceremony', path: 'naming-ceremony' },
  ];

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleInvitationCardNavigation = (path_url) => {
    navigate(`/cards/${path_url}`);
  };
  // const handleDownloadnavigation = () => {
  //   navigate(0);
  //   navigate(`/android-app`);

  // }
  useEffect(() => {
    if('scrollRestoration' in window.history){
      window.history.scrollRestoration = 'manual';
    }
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };

    handleScrollToTop();

    return () => {
      if( 'scrollRestoration' in window.history){
        window.history.scrollRestoration = 'auto';
      }
    };
    
  }, []);


  return (
    <div>
      

      {/* Footer Section */}
      <Box
        sx={{
          backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : 'white',
          color: isDarkMode ? 'black' : 'black',
          padding: 4,
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Grid container justifyContent="space-around">
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" sx={{ fontWeight: 800, color: isDarkMode ? 'black' : 'black' }}>
              RajOffset
            </Typography>
            <Typography variant="body1" sx={{ paddingRight: '15px', paddingTop: '10px', color: isDarkMode ? 'black' : 'black' }}>
              RajOffset is an online platform for creating Customized Wedding and other personalized invitations for your loved ones...
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" sx={{ fontWeight: 800, color: isDarkMode ? 'black' : 'black' }}>
              Top Links
            </Typography>
            {invitationCategory.map((item, index) => (
              <Typography
                variant="body1"
                key={index}
                sx={{ paddingTop: '10px', color: isDarkMode ? 'black' : 'black', cursor: 'pointer' }}
                onClick={() => handleInvitationCardNavigation(item.path)}
              >
                 {item.label}
              </Typography>
            ))}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" sx={{ fontWeight: 800, color: isDarkMode ? 'black' : 'black' }}>
              Support
            </Typography>
            {menuItems.map((item, index) => (
              <Typography
                variant="body1"
                key={index}
                sx={{ paddingTop: '10px',  color: isDarkMode ? 'black' : 'black', cursor: 'pointer' }}
                onClick={() => handleNavigation(item.path)}
              >
                {item.label}
              </Typography>
            ))}
          </Grid>
          <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'left' }}>
            Version {appVersion} - powered by Awesome Innovators
          </Typography>
        </Grid>
      </Box>
    </div>
  );
}

export default Footer;
