import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Grid,
    Typography,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    Stepper,
    Step,
    StepLabel,
    Paper,
    useTheme,
    useMediaQuery,
    Container,
    IconButton,
    MobileStepper
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createCardForDiwaliOfferData } from '../../redux/actions/diwaliOfferActions';
import countries from "../../components/countries.json";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

// Add this custom styled component for the country selector
const CountryMenuItem = ({ country }) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            py: 1,
            px: 2,
            width: '100%',
            '&:hover': {
                backgroundColor: 'action.hover',
            }
        }}
    >
        <img
            src={country.flag}
            alt={country.name}
            style={{ width: 24, height: 16, objectFit: 'cover', borderRadius: 2 }}
        />
        <Box sx={{ flex: 1 }}>
            <Typography variant="body2" noWrap>
                {country.name}
            </Typography>
            <Typography variant="caption" color="text.secondary">
                {country.dial_code}
            </Typography>
        </Box>
    </Box>
);

function Holi2025Offer() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const stepperRef = useRef(null);

    const [activeStep, setActiveStep] = useState(0);
    const steps = [
        'Contact Info',
        'Select Card Type',
        'Event Details',
        'Bride Details',
        'Groom Details',
        'Family Details'
    ];

    const [formData, setFormData] = useState({
        "Tilak Event Date": '',
        "Tilak Event Time": '',
        "Tilak Venue": '',
        "Haldi Event Date": '',
        "Haldi Event Time": '',
        "Haldi Venue": '',
        "Mehndi Event Date": '',
        "Mehndi Event Time": '',
        "Mehndi Venue": '',
        "Sangeet Event Date": '',
        "Sangeet Event Time": '',
        "Sangeet Venue": '',
        "Wedding Event Date": '',
        "Wedding Event Time": '',
        "Wedding Venue": '',
        "Bride Name": '',
        "Bride Parent Name": '',
        "Bride Address": '',
        "Groom Name": '',
        "Groom Parent Name": '',
        "Groom Address": '',
        "Family Members": '',
        "Message": '@lovelycouples',
        "Card For": 'bride',
        "Customer Name": '',
        "Customer Mobile": '',
        "Customer Email": ''
    });

    const [timeLeft, setTimeLeft] = useState('');
    const [selectedSide, setSelectedSide] = useState('bride');
    const [customerMobile, setCustomerMobile] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(countries[0]);
    const [selectedPhoneLength, setSelectedPhoneLength] = useState(countries[0].phoneLength);

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = new Date();
            const currentHour = now.getHours();
            const slotEndHour = Math.ceil((currentHour + 1) / 6) * 6;
            const slotEnd = new Date(now);
            slotEnd.setHours(slotEndHour, 0, 0, 0);
            const timeDiff = slotEnd - now;
            const hours = Math.floor(timeDiff / (1000 * 60 * 60));
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
            setTimeLeft(`${String(hours).padStart(2, '0')} hr ${String(minutes).padStart(2, '0')} min ${String(seconds).padStart(2, '0')} sec`);
        };

        calculateTimeLeft();
        const timer = setInterval(calculateTimeLeft, 1000);
        return () => clearInterval(timer);
    }, []);

    const handleFieldChange = (field, event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCountryChange = (event) => {
        const country = countries.find((c) => c.dial_code === event.target.value);
        setSelectedCountry(country);
        setSelectedPhoneLength(country?.phoneLength);
    };

    const handleNext = () => {
        setActiveStep((prevStep) => prevStep + 1);
        if (stepperRef.current) {
            const activeStepElement = stepperRef.current.querySelector('.Mui-active');
            if (activeStepElement) {
                activeStepElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
        if (stepperRef.current) {
            const activeStepElement = stepperRef.current.querySelector('.Mui-active');
            if (activeStepElement) {
                activeStepElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
            }
        }
    };

    const handleStepClick = (step) => {
        // Only allow moving forward if all previous steps are valid
        if (step > activeStep) {
            for (let i = 0; i <= step - 1; i++) {
                if (!isStepValid(i)) {
                    return; // Don't allow moving forward if any previous step is invalid
                }
            }
        }
        setActiveStep(step);
        if (stepperRef.current) {
            const activeStepElement = stepperRef.current.querySelector('.Mui-active');
            if (activeStepElement) {
                activeStepElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
            }
        }
    };

    const isStepValid = (step) => {
        switch (step) {
            case 0: // Contact Info
                return formData["Customer Name"] && customerMobile.length === selectedPhoneLength && formData["Customer Email"];
            case 1: // Card Type
                return formData["Card For"] !== '';
            case 2: // Event Details
                return formData["Wedding Event Date"] && formData["Wedding Event Time"] && formData["Wedding Venue"];
            case 3: // Bride Details
                return formData["Bride Name"] && formData["Bride Parent Name"] && formData["Bride Address"];
            case 4: // Groom Details
                return formData["Groom Name"] && formData["Groom Parent Name"] && formData["Groom Address"];
            case 5: // Family Details
                return formData["Family Members"] && formData["Message"];
            default:
                return true;
        }
    };

    const handleSubmit = async () => {
        const transformedData = {
            campaign: "hindu-special-99",
            customer_name: formData["Customer Name"],
            customer_mobile: customerMobile,
            customer_email: formData["Customer Email"],
            country_code: selectedCountry?.dial_code,
            card_fields: {
                "Tilak Date": formatDate(formData["Tilak Event Date"]),
                "Tilak Time": formatTime(formData["Tilak Event Time"]),
                "Tilak Venue": formData["Tilak Venue"],
                "Haldi Date": formatDate(formData["Haldi Event Date"]),
                "Haldi Time": formatTime(formData["Haldi Event Time"]),
                "Haldi Venue": formData["Haldi Venue"],
                "Mehndi Date": formatDate(formData["Mehndi Event Date"]),
                "Mehndi Time": formatTime(formData["Mehndi Event Time"]),
                "Mehndi Venue": formData["Mehndi Venue"],
                "Sangeet Date": formatDate(formData["Sangeet Event Date"]),
                "Sangeet Time": formatTime(formData["Sangeet Event Time"]),
                "Sangeet Venue": formData["Sangeet Venue"],
                "Wedding Date": formatDate(formData["Wedding Event Date"]),
                "Wedding Time": formatTime(formData["Wedding Event Time"]),
                "Wedding Venue": formData["Wedding Venue"],
                "Bride Name": formData["Bride Name"],
                "Bride Parent Name": formData["Bride Parent Name"],
                "Bride Address": formData["Bride Address"],
                "Groom Name": formData["Groom Name"],
                "Groom Parent Name": formData["Groom Parent Name"],
                "Groom Address": formData["Groom Address"],
                "Family Members": formData["Family Members"],
                "Message": formData["Message"],
                "Card For": formData["Card For"]
            }
        };

        const data = await createCardForDiwaliOfferData(transformedData);
        if (data.message === "Card created successfully") {
            navigate(`/form-submission/${data?.data?.attempt_id}`);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    };

    const formatTime = (timeString) => {
        if (!timeString) return '';
        const [hours, minutes] = timeString.split(":");
        const hours12 = ((+hours % 12) || 12).toString().padStart(2, '0');
        const period = +hours >= 12 ? 'PM' : 'AM';
        return `${hours12}:${minutes} ${period}`;
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 0: // Contact Info
    return (
                    <Box sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Contact Information
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name="Customer Name"
                                    label="Your FullName"
                                    value={formData["Customer Name"]}
                                    onChange={event => handleFieldChange("text", event)}
                                    required
                                    placeholder="Enter your full name"
                                    InputProps={{
                                        sx: { borderRadius: 2 }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
                                    <FormControl sx={{ minWidth: 200 }} fullWidth>
                                        <InputLabel id="country-code-label">Country</InputLabel>
                                        <Select
                                            labelId="country-code-label"
                                            value={selectedCountry?.dial_code}
                                            onChange={handleCountryChange}
                                            label="Country"
                                            sx={{
                                                borderRadius: 2,
                                                '& .MuiSelect-select': {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1
                                                }
                                            }}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <img
                                                        src={selectedCountry?.flag}
                                                        alt={selectedCountry?.name}
                                                        style={{ width: 24, height: 16, objectFit: 'cover', borderRadius: 2 }}
                                                    />
                                                    <Typography variant="body2">
                                                        {selectedCountry?.name} ({selected})
                                                    </Typography>
                                                </Box>
                                            )}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        maxHeight: 300,
                                                        '& .MuiMenuItem-root': {
                                                            padding: 0
                                                        }
                                                    }
                                                }
                                            }}
                                        >
                                            {countries.map((country) => (
                                                <MenuItem key={country.dial_code} value={country.dial_code}>
                                                    <CountryMenuItem country={country} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="WhatsApp Number"
                                    value={customerMobile}
                                    onChange={event => setCustomerMobile(event.target.value)}
                                    required
                                    placeholder="Enter your WhatsApp number"
                                    type="tel"
                                    InputProps={{
                                        sx: { borderRadius: 2 },
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Typography variant="body2" color="text.secondary">
                                                    {selectedCountry?.dial_code}
                                                </Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                    helperText={`Enter ${selectedPhoneLength} digits number`}
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name="Customer Email"
                                    label="Email (Optional)"
                                    type="email"
                                    value={formData["Customer Email"]}
                                    onChange={event => handleFieldChange("text", event)}
                                    placeholder="Enter your email address"
                                    InputProps={{
                                        sx: { borderRadius: 2 },
                                        
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                );

            case 1: // Card Type
                return (
                    <Box sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            You are making card for
                    </Typography>
                        <FormControl fullWidth required>
                            <InputLabel>Making Card For</InputLabel>
                                <Select
                                    name="Card For"
                                value={formData["Card For"]}
                                    onChange={event => handleFieldChange({}, event)}
                            >
                                <MenuItem value="bride">Bride/Girl (side)</MenuItem>
                                <MenuItem value="groom">Groom/Boy (side)</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                );

            case 2:
                return (
                    <Box sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Event Details
                                </Typography>
                        <Grid container spacing={3}>
                            {/* Haldi Ceremony */}
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom sx={{ color: 'primary.main', fontWeight: 'medium' }}>
                                    Haldi Ceremony
                                </Typography>
                            </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="Haldi Event Date"
                                    label="Haldi Date"
                                            type="date"
                                            value={formData["Haldi Event Date"]}
                                            onChange={event => handleFieldChange("date", event)}
                                    required
                                            InputLabelProps={{ shrink: true }}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="Haldi Event Time"
                                    label="Haldi Time"
                                            type="time"
                                            value={formData["Haldi Event Time"]}
                                            onChange={event => handleFieldChange("time", event)}
                                    required
                                            InputLabelProps={{ shrink: true }}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="Haldi Venue"
                                            label="Haldi Venue"
                                            value={formData["Haldi Venue"]}
                                            onChange={event => handleFieldChange("text", event)}
                                    required
                                    placeholder="Enter venue details"
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                                        />
                                    </Grid>

                            {/* Mehndi Ceremony */}
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom sx={{ color: 'primary.main', fontWeight: 'medium', mt: 2 }}>
                                    Mehndi Ceremony
                                </Typography>
                            </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="Mehndi Event Date"
                                    label="Mehndi Date"
                                            type="date"
                                            value={formData["Mehndi Event Date"]}
                                            onChange={event => handleFieldChange("date", event)}
                                    required
                                            InputLabelProps={{ shrink: true }}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="Mehndi Event Time"
                                    label="Mehndi Time"
                                            type="time"
                                            value={formData["Mehndi Event Time"]}
                                            onChange={event => handleFieldChange("time", event)}
                                    required
                                            InputLabelProps={{ shrink: true }}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="Mehndi Venue"
                                            label="Mehndi Venue"
                                            value={formData["Mehndi Venue"]}
                                            onChange={event => handleFieldChange("text", event)}
                                    required
                                    placeholder="Enter venue details"
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                                        />
                                    </Grid>

                            {/* Sangeet Ceremony */}
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom sx={{ color: 'primary.main', fontWeight: 'medium', mt: 2 }}>
                                    Sangeet Ceremony
                                </Typography>
                            </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="Sangeet Event Date"
                                    label="Sangeet Date"
                                            type="date"
                                            value={formData["Sangeet Event Date"]}
                                            onChange={event => handleFieldChange("date", event)}
                                    required
                                            InputLabelProps={{ shrink: true }}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="Sangeet Event Time"
                                    label="Sangeet Time"
                                            type="time"
                                            value={formData["Sangeet Event Time"]}
                                            onChange={event => handleFieldChange("time", event)}
                                    required
                                            InputLabelProps={{ shrink: true }}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="Sangeet Venue"
                                            label="Sangeet Venue"
                                            value={formData["Sangeet Venue"]}
                                            onChange={event => handleFieldChange("text", event)}
                                    required
                                    placeholder="Enter venue details"
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                                        />
                                    </Grid>

                            {/* Tilak Ceremony */}
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom sx={{ color: 'primary.main', fontWeight: 'medium', mt: 2 }}>
                                    Tilak Ceremony (For Groom Side Only)
                                </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="Tilak Event Date"
                                    label="Tilak Date"
                                            type="date"
                                            value={formData["Tilak Event Date"]}
                                            onChange={event => handleFieldChange("date", event)}
                                    required
                                            InputLabelProps={{ shrink: true }}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="Tilak Event Time"
                                    label="Tilak Time"
                                            type="time"
                                            value={formData["Tilak Event Time"]}
                                            onChange={event => handleFieldChange("time", event)}
                                    required
                                            InputLabelProps={{ shrink: true }}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="Tilak Venue"
                                            label="Tilak Venue"
                                            value={formData["Tilak Venue"]}
                                            onChange={event => handleFieldChange("text", event)}
                                    required
                                    placeholder="Enter venue details"
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                                        />
                                    </Grid>

                            {/* Wedding Ceremony */}
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom sx={{ color: 'primary.main', fontWeight: 'medium', mt: 2 }}>
                                    Wedding Ceremony
                                </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="Wedding Event Date"
                                    label="Wedding Date"
                                            type="date"
                                            value={formData["Wedding Event Date"]}
                                            onChange={event => handleFieldChange("date", event)}
                                    required
                                            InputLabelProps={{ shrink: true }}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="Wedding Event Time"
                                    label="Wedding Time"
                                            type="time"
                                            value={formData["Wedding Event Time"]}
                                            onChange={event => handleFieldChange("time", event)}
                                    required
                                            InputLabelProps={{ shrink: true }}
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="Wedding Venue"
                                            label="Wedding Venue"
                                            value={formData["Wedding Venue"]}
                                            onChange={event => handleFieldChange("text", event)}
                                    required
                                    placeholder="Enter venue details"
                                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                );

            case 3:
                return (
                    <Box sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Bride Details
                            </Typography>
                            <Grid container spacing={2}>
                            <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name="Bride Name"
                                    label="Bride Name"
                                        value={formData["Bride Name"]}
                                    onChange={event => handleFieldChange("text", event)}
                                        inputProps={{ maxLength: 30 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        name="Bride Parent Name"
                                    label="Parent's Name"
                                        value={formData["Bride Parent Name"]}
                                    onChange={event => handleFieldChange("text", event)}
                                        inputProps={{ maxLength: 80 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        name="Bride Address"
                                    label="Address"
                                        value={formData["Bride Address"]}
                                    onChange={event => handleFieldChange("text", event)}
                                        inputProps={{ maxLength: 80 }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                );

            case 4:
                return (
                    <Box sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Groom Details
                            </Typography>
                            <Grid container spacing={2}>
                            <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name="Groom Name"
                                    label="Groom Name"
                                        value={formData["Groom Name"]}
                                    onChange={event => handleFieldChange("text", event)}
                                        inputProps={{ maxLength: 30 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        name="Groom Parent Name"
                                    label="Parent's Name"
                                        value={formData["Groom Parent Name"]}
                                    onChange={event => handleFieldChange("text", event)}
                                        inputProps={{ maxLength: 80 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        name="Groom Address"
                                    label="Address"
                                        value={formData["Groom Address"]}
                                    onChange={event => handleFieldChange("text", event)}
                                        inputProps={{ maxLength: 80 }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                );

            case 5:
                return (
                    <Box sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Family Details
                            </Typography>
                            <Grid container spacing={2}>
                            <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                    multiline
                                    rows={4}
                                        name="Family Members"
                                    label="Family Members"
                                        value={formData["Family Members"]}
                                    onChange={event => handleFieldChange("text", event)}
                                        inputProps={{ maxLength: 220 }}
                                    />
                                </Grid>
                            <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name="Message"
                                        label="Message"
                                        value={formData["Message"]}
                                    onChange={event => handleFieldChange("text", event)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                );

            default:
                return null;
        }
    };

    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
                
            <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
                


                <Box sx={{ p: 3, bgcolor: 'primary.main', color: 'primary.contrastText' }}>
                    <Typography variant="h5" gutterBottom>
                        Wedding Invitation Card
                    </Typography>
                    <Typography variant="subtitle2">
                        Time left in current slot: {timeLeft}
                    </Typography>
                    <Typography variant="subtitle2" onClick={() => {
                        window.open('https://rajoffset.com/bundle-order/b98ab186-43b6-4da4-884c-60184dc16398', '_blank');
                    }}
                    >
                        Click Here to view sample
                            </Typography>
                        </Box>

                {isMobile ? (
                    <Box sx={{ width: '100%', overflow: 'auto', position: 'relative' }}>
                        <Box
                            ref={stepperRef}
                            sx={{
                                display: 'flex',
                                width: 'max-content',
                                minWidth: '100%',
                                p: 2,
                                scrollBehavior: 'smooth'
                            }}
                        >
                            {steps.map((label, index) => (
                                <Box
                                    key={label}
                                    onClick={() => handleStepClick(index)}
                                    sx={{
                                        minWidth: '120px',
                                        textAlign: 'center',
                                        px: 1,
                                        opacity: activeStep === index ? 1 : 0.5,
                                        borderBottom: activeStep === index ? '2px solid' : 'none',
                                        borderColor: 'primary.main',
                                        transition: 'all 0.3s ease',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            opacity: 0.8,
                                            backgroundColor: 'action.hover'
                                        }
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        color={activeStep === index ? 'primary' : 'text.secondary'}
                                                            sx={{
                                            whiteSpace: 'nowrap',
                                            fontWeight: activeStep === index ? 'medium' : 'regular'
                                        }}
                                    >
                                        {label}
                                    </Typography>
                                    <Box
                                        sx={{
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            margin: '4px auto 0',
                                            backgroundColor: isStepValid(index) ? 'success.main' : 'action.disabled'
                                        }}
                                    />
                        </Box>
                            ))}
                        </Box>
                    </Box>
                ) : (
                    <Stepper
                        activeStep={activeStep}
                        alternativeLabel
                        sx={{ pt: 3, pb: 2 }}
                        nonLinear
                    >
                        {steps.map((label, index) => (
                            <Step 
                                key={label} 
                                completed={isStepValid(index)}
                                onClick={() => handleStepClick(index)}
                                sx={{ 
                                    cursor: 'pointer',
                                    '& .MuiStepLabel-root': {
                                        '&:hover': {
                                            backgroundColor: 'action.hover',
                                            borderRadius: 1
                                        }
                                    }
                                }}
                            >
                                <StepLabel 
                                    error={!isStepValid(index) && index < activeStep}
                                    optional={
                                        isStepValid(index) ? (
                                            <Typography variant="caption" color="success.main">
                                                Complete
                    </Typography>
                                        ) : null
                                    }
                                >
                                    {label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                )}

                {renderStepContent(activeStep)}

                <Box sx={{ p: 3, bgcolor: 'grey.50' }}>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                variant="outlined"
                                startIcon={<KeyboardArrowLeft />}
                            >
                                Back
                            </Button>
                        </Grid>
                        <Grid item>
                            {activeStep === steps.length - 1 ? (
                    <Button
                        variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                    disabled={!isStepValid(activeStep)}
                                >
                                    Submit
                    </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    disabled={!isStepValid(activeStep)}
                                    endIcon={<KeyboardArrowRight />}
                                >
                                    Next
                                </Button>
                            )}
                    </Grid>
                    </Grid>
                </Box>
            </Paper>

            <Paper elevation={1} sx={{ mt: 3, p: 2 }}>
                <Typography variant="body2" color="text.secondary">
                    Note: You cannot modify the details once submitted. Please ensure all information is correct.
                    Card and video creation may take up to 4 hours. Download link will be sent via email/WhatsApp.
                </Typography>
            </Paper>
        </Container>
    );
}

export default Holi2025Offer;
