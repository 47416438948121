import React from 'react';
import './App.css';
import RouterSetup from './routers/router';
import { useTheme } from './theme/ThemeContext';
import { Provider } from 'react-redux';
import {store} from './redux/store'; 

function App() {
  const { isDarkMode } = useTheme();

  return (

    <Provider store={store}>
      <div className={isDarkMode ? 'dark-mode' : 'light-mode'}>
        <RouterSetup />
      </div>
    </Provider>
  );
}

export default App;
